@font-face {
  font-family: Fjalla One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("fjalla-one-cyrillic-ext-400-normal.0dfd5eab.woff2") format("woff2"), url("fjalla-one-cyrillic-ext-400-normal.004bd8c0.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Fjalla One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("fjalla-one-vietnamese-400-normal.21d6a23d.woff2") format("woff2"), url("fjalla-one-vietnamese-400-normal.64e204c7.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Fjalla One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("fjalla-one-latin-ext-400-normal.088803f8.woff2") format("woff2"), url("fjalla-one-latin-ext-400-normal.e318911c.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Fjalla One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("fjalla-one-latin-400-normal.a0f22405.woff2") format("woff2"), url("fjalla-one-latin-400-normal.a743d45f.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Raleway;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("raleway-cyrillic-ext-300-normal.db601969.woff2") format("woff2"), url("raleway-cyrillic-ext-300-normal.b7befa7f.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Raleway;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("raleway-cyrillic-300-normal.300ad1da.woff2") format("woff2"), url("raleway-cyrillic-300-normal.6fa40810.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Raleway;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("raleway-vietnamese-300-normal.e542af95.woff2") format("woff2"), url("raleway-vietnamese-300-normal.e874ccba.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Raleway;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("raleway-latin-ext-300-normal.f1327bb3.woff2") format("woff2"), url("raleway-latin-ext-300-normal.5bc2e8a8.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Raleway;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("raleway-latin-300-normal.e32cb8fc.woff2") format("woff2"), url("raleway-latin-300-normal.89b72bda.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Raleway;
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: url("raleway-cyrillic-ext-300-italic.f790995e.woff2") format("woff2"), url("raleway-cyrillic-ext-300-italic.a4628a27.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Raleway;
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: url("raleway-cyrillic-300-italic.d6fb7f19.woff2") format("woff2"), url("raleway-cyrillic-300-italic.6c8e20e9.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Raleway;
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: url("raleway-vietnamese-300-italic.15b845de.woff2") format("woff2"), url("raleway-vietnamese-300-italic.db5c6078.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Raleway;
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: url("raleway-latin-ext-300-italic.aadf907e.woff2") format("woff2"), url("raleway-latin-ext-300-italic.02669ed4.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Raleway;
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: url("raleway-latin-300-italic.15bc63b2.woff2") format("woff2"), url("raleway-latin-300-italic.f288366d.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Raleway;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("raleway-cyrillic-ext-400-normal.aefe6e33.woff2") format("woff2"), url("raleway-cyrillic-ext-400-normal.b057df9e.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Raleway;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("raleway-cyrillic-400-normal.e68c7b39.woff2") format("woff2"), url("raleway-cyrillic-400-normal.a41fdf3f.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Raleway;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("raleway-vietnamese-400-normal.b5a2c393.woff2") format("woff2"), url("raleway-vietnamese-400-normal.0cc1bfe7.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Raleway;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("raleway-latin-ext-400-normal.b270b24f.woff2") format("woff2"), url("raleway-latin-ext-400-normal.1410ed68.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Raleway;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("raleway-latin-400-normal.fb8e2136.woff2") format("woff2"), url("raleway-latin-400-normal.83d87682.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Raleway;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("raleway-cyrillic-ext-400-italic.0f1a9169.woff2") format("woff2"), url("raleway-cyrillic-ext-400-italic.9f08684a.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Raleway;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("raleway-cyrillic-400-italic.7bc6dff4.woff2") format("woff2"), url("raleway-cyrillic-400-italic.1fb85a79.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Raleway;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("raleway-vietnamese-400-italic.9055587c.woff2") format("woff2"), url("raleway-vietnamese-400-italic.b9acf4a0.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Raleway;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("raleway-latin-ext-400-italic.99767acb.woff2") format("woff2"), url("raleway-latin-ext-400-italic.40bfeb64.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Raleway;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("raleway-latin-400-italic.1a8a2ace.woff2") format("woff2"), url("raleway-latin-400-italic.345d9104.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Raleway;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("raleway-cyrillic-ext-500-normal.9f2739e8.woff2") format("woff2"), url("raleway-cyrillic-ext-500-normal.07720916.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Raleway;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("raleway-cyrillic-500-normal.12d3f433.woff2") format("woff2"), url("raleway-cyrillic-500-normal.992e0d15.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Raleway;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("raleway-vietnamese-500-normal.304afa8b.woff2") format("woff2"), url("raleway-vietnamese-500-normal.872df4b0.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Raleway;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("raleway-latin-ext-500-normal.d9fab7f5.woff2") format("woff2"), url("raleway-latin-ext-500-normal.0ff197d1.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Raleway;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("raleway-latin-500-normal.ed94cfc0.woff2") format("woff2"), url("raleway-latin-500-normal.881f971c.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Raleway;
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src: url("raleway-cyrillic-ext-500-italic.20791944.woff2") format("woff2"), url("raleway-cyrillic-ext-500-italic.abc4ad26.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Raleway;
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src: url("raleway-cyrillic-500-italic.ab77a459.woff2") format("woff2"), url("raleway-cyrillic-500-italic.1864502c.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Raleway;
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src: url("raleway-vietnamese-500-italic.3290dc54.woff2") format("woff2"), url("raleway-vietnamese-500-italic.d4f2ddbf.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Raleway;
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src: url("raleway-latin-ext-500-italic.05c0b1f6.woff2") format("woff2"), url("raleway-latin-ext-500-italic.7f71f434.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Raleway;
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src: url("raleway-latin-500-italic.65333ca0.woff2") format("woff2"), url("raleway-latin-500-italic.c6b3dabc.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Raleway;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("raleway-cyrillic-ext-600-normal.55e246a5.woff2") format("woff2"), url("raleway-cyrillic-ext-600-normal.a8e0c770.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Raleway;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("raleway-cyrillic-600-normal.1e941fd0.woff2") format("woff2"), url("raleway-cyrillic-600-normal.88290c7a.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Raleway;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("raleway-vietnamese-600-normal.590f474d.woff2") format("woff2"), url("raleway-vietnamese-600-normal.f6d0a315.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Raleway;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("raleway-latin-ext-600-normal.6b177585.woff2") format("woff2"), url("raleway-latin-ext-600-normal.d85875d9.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Raleway;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("raleway-latin-600-normal.f794cf62.woff2") format("woff2"), url("raleway-latin-600-normal.0f2b7637.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Raleway;
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url("raleway-cyrillic-ext-600-italic.03a4cd3a.woff2") format("woff2"), url("raleway-cyrillic-ext-600-italic.e08e9ac7.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Raleway;
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url("raleway-cyrillic-600-italic.cce34603.woff2") format("woff2"), url("raleway-cyrillic-600-italic.8d5f053e.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Raleway;
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url("raleway-vietnamese-600-italic.6be53548.woff2") format("woff2"), url("raleway-vietnamese-600-italic.17c1dcda.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Raleway;
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url("raleway-latin-ext-600-italic.362904aa.woff2") format("woff2"), url("raleway-latin-ext-600-italic.4fe9282b.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Raleway;
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url("raleway-latin-600-italic.cbc631ce.woff2") format("woff2"), url("raleway-latin-600-italic.d5bf4c68.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Raleway;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("raleway-cyrillic-ext-700-normal.47c1ce58.woff2") format("woff2"), url("raleway-cyrillic-ext-700-normal.6bcb9391.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Raleway;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("raleway-cyrillic-700-normal.3958da7f.woff2") format("woff2"), url("raleway-cyrillic-700-normal.ec6a8721.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Raleway;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("raleway-vietnamese-700-normal.971e45e1.woff2") format("woff2"), url("raleway-vietnamese-700-normal.e0542867.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Raleway;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("raleway-latin-ext-700-normal.89106b91.woff2") format("woff2"), url("raleway-latin-ext-700-normal.291ce4cc.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Raleway;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("raleway-latin-700-normal.0f9257f3.woff2") format("woff2"), url("raleway-latin-700-normal.4617819a.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Raleway;
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url("raleway-cyrillic-ext-700-italic.f4c1b950.woff2") format("woff2"), url("raleway-cyrillic-ext-700-italic.2e9c13f0.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Raleway;
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url("raleway-cyrillic-700-italic.3f146cd7.woff2") format("woff2"), url("raleway-cyrillic-700-italic.98d3d6a0.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Raleway;
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url("raleway-vietnamese-700-italic.2116c9d4.woff2") format("woff2"), url("raleway-vietnamese-700-italic.e3b960f7.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Raleway;
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url("raleway-latin-ext-700-italic.2341b4de.woff2") format("woff2"), url("raleway-latin-ext-700-italic.c8e21fd4.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Raleway;
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url("raleway-latin-700-italic.5099a916.woff2") format("woff2"), url("raleway-latin-700-italic.eab65249.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Merriweather;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("merriweather-cyrillic-ext-400-normal.0c4e4336.woff2") format("woff2"), url("merriweather-cyrillic-ext-400-normal.fcf4b499.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Merriweather;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("merriweather-cyrillic-400-normal.be98b155.woff2") format("woff2"), url("merriweather-cyrillic-400-normal.0b23cd73.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Merriweather;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("merriweather-vietnamese-400-normal.e675eecd.woff2") format("woff2"), url("merriweather-vietnamese-400-normal.86a2855c.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Merriweather;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("merriweather-latin-ext-400-normal.5f51fcc7.woff2") format("woff2"), url("merriweather-latin-ext-400-normal.73735901.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Merriweather;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("merriweather-latin-400-normal.395cc7e5.woff2") format("woff2"), url("merriweather-latin-400-normal.0d1d7ca9.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Merriweather;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("merriweather-cyrillic-ext-400-italic.54bc3887.woff2") format("woff2"), url("merriweather-cyrillic-ext-400-italic.b1fb5fe5.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Merriweather;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("merriweather-cyrillic-400-italic.e5e0c915.woff2") format("woff2"), url("merriweather-cyrillic-400-italic.9025ff92.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Merriweather;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("merriweather-vietnamese-400-italic.b3566f5d.woff2") format("woff2"), url("merriweather-vietnamese-400-italic.aeb46850.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Merriweather;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("merriweather-latin-ext-400-italic.854d2997.woff2") format("woff2"), url("merriweather-latin-ext-400-italic.20dbf9c1.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Merriweather;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("merriweather-latin-400-italic.ffb80337.woff2") format("woff2"), url("merriweather-latin-400-italic.d59b62b2.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Merriweather;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("merriweather-cyrillic-ext-700-normal.3b2f6e5a.woff2") format("woff2"), url("merriweather-cyrillic-ext-700-normal.5b7abef9.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Merriweather;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("merriweather-cyrillic-700-normal.4b18832b.woff2") format("woff2"), url("merriweather-cyrillic-700-normal.afcc7858.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Merriweather;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("merriweather-vietnamese-700-normal.7046b248.woff2") format("woff2"), url("merriweather-vietnamese-700-normal.9c6e0c0f.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Merriweather;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("merriweather-latin-ext-700-normal.f31022bb.woff2") format("woff2"), url("merriweather-latin-ext-700-normal.a45979cb.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Merriweather;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("merriweather-latin-700-normal.1899c1c6.woff2") format("woff2"), url("merriweather-latin-700-normal.bebe4a6a.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Merriweather;
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url("merriweather-cyrillic-ext-700-italic.49113e72.woff2") format("woff2"), url("merriweather-cyrillic-ext-700-italic.cc5fcaa0.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Merriweather;
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url("merriweather-cyrillic-700-italic.2726ad3f.woff2") format("woff2"), url("merriweather-cyrillic-700-italic.d53115cb.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Merriweather;
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url("merriweather-vietnamese-700-italic.3d9218bc.woff2") format("woff2"), url("merriweather-vietnamese-700-italic.0168892c.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Merriweather;
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url("merriweather-latin-ext-700-italic.c4b4d46c.woff2") format("woff2"), url("merriweather-latin-ext-700-italic.df7a5820.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Merriweather;
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url("merriweather-latin-700-italic.0d5c3f0f.woff2") format("woff2"), url("merriweather-latin-700-italic.909b494c.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root {
  --dt-row-selected: 0, 137, 182;
  --dt-row-selected-text: 255, 255, 255;
  --dt-row-selected-link: 9, 10, 11;
  --dt-row-stripe: 0, 0, 0;
  --dt-row-hover: 0, 0, 0;
  --dt-column-ordering: 0, 0, 0;
  --dt-html-background: white;
}

:root.dark {
  --dt-html-background: #212529;
}

table.dataTable td.dt-control {
  text-align: center;
  cursor: pointer;
}

table.dataTable td.dt-control:before {
  box-sizing: border-box;
  content: "";
  border: 5px solid #0000;
  border-left: 10px solid #00000080;
  border-right-width: 0;
  display: inline-block;
}

table.dataTable tr.dt-hasChild td.dt-control:before {
  border: 5px solid #0000;
  border-top: 10px solid #00000080;
  border-bottom-width: 0;
}

html.dark table.dataTable td.dt-control:before, :root[data-bs-theme="dark"] table.dataTable td.dt-control:before, :root[data-theme="dark"] table.dataTable td.dt-control:before {
  border-left-color: #ffffff80;
}

html.dark table.dataTable tr.dt-hasChild td.dt-control:before, :root[data-bs-theme="dark"] table.dataTable tr.dt-hasChild td.dt-control:before, :root[data-theme="dark"] table.dataTable tr.dt-hasChild td.dt-control:before {
  border-top-color: #ffffff80;
  border-left-color: #0000;
}

div.dt-scroll {
  width: 100%;
}

div.dt-scroll-body thead tr, div.dt-scroll-body tfoot tr {
  height: 0;
}

div.dt-scroll-body thead tr th, div.dt-scroll-body thead tr td, div.dt-scroll-body tfoot tr th, div.dt-scroll-body tfoot tr td {
  border-top-width: 0 !important;
  border-bottom-width: 0 !important;
  height: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

div.dt-scroll-body thead tr th div.dt-scroll-sizing, div.dt-scroll-body thead tr td div.dt-scroll-sizing, div.dt-scroll-body tfoot tr th div.dt-scroll-sizing, div.dt-scroll-body tfoot tr td div.dt-scroll-sizing {
  height: 0 !important;
  overflow: hidden !important;
}

table.dataTable thead > tr > th.dt-orderable-asc span.dt-column-order:before, table.dataTable thead > tr > th.dt-ordering-asc span.dt-column-order:before, table.dataTable thead > tr > td.dt-orderable-asc span.dt-column-order:before, table.dataTable thead > tr > td.dt-ordering-asc span.dt-column-order:before {
  content: "▲";
  content: "▲" / "";
  display: block;
  position: absolute;
  bottom: 50%;
}

table.dataTable thead > tr > th.dt-orderable-desc span.dt-column-order:after, table.dataTable thead > tr > th.dt-ordering-desc span.dt-column-order:after, table.dataTable thead > tr > td.dt-orderable-desc span.dt-column-order:after, table.dataTable thead > tr > td.dt-ordering-desc span.dt-column-order:after {
  content: "▼";
  content: "▼" / "";
  display: block;
  position: absolute;
  top: 50%;
}

table.dataTable thead > tr > th.dt-orderable-asc, table.dataTable thead > tr > th.dt-orderable-desc, table.dataTable thead > tr > th.dt-ordering-asc, table.dataTable thead > tr > th.dt-ordering-desc, table.dataTable thead > tr > td.dt-orderable-asc, table.dataTable thead > tr > td.dt-orderable-desc, table.dataTable thead > tr > td.dt-ordering-asc, table.dataTable thead > tr > td.dt-ordering-desc {
  padding-right: 30px;
  position: relative;
}

table.dataTable thead > tr > th.dt-orderable-asc span.dt-column-order, table.dataTable thead > tr > th.dt-orderable-desc span.dt-column-order, table.dataTable thead > tr > th.dt-ordering-asc span.dt-column-order, table.dataTable thead > tr > th.dt-ordering-desc span.dt-column-order, table.dataTable thead > tr > td.dt-orderable-asc span.dt-column-order, table.dataTable thead > tr > td.dt-orderable-desc span.dt-column-order, table.dataTable thead > tr > td.dt-ordering-asc span.dt-column-order, table.dataTable thead > tr > td.dt-ordering-desc span.dt-column-order {
  width: 12px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 12px;
}

table.dataTable thead > tr > th.dt-orderable-asc span.dt-column-order:before, table.dataTable thead > tr > th.dt-orderable-asc span.dt-column-order:after, table.dataTable thead > tr > th.dt-orderable-desc span.dt-column-order:before, table.dataTable thead > tr > th.dt-orderable-desc span.dt-column-order:after, table.dataTable thead > tr > th.dt-ordering-asc span.dt-column-order:before, table.dataTable thead > tr > th.dt-ordering-asc span.dt-column-order:after, table.dataTable thead > tr > th.dt-ordering-desc span.dt-column-order:before, table.dataTable thead > tr > th.dt-ordering-desc span.dt-column-order:after, table.dataTable thead > tr > td.dt-orderable-asc span.dt-column-order:before, table.dataTable thead > tr > td.dt-orderable-asc span.dt-column-order:after, table.dataTable thead > tr > td.dt-orderable-desc span.dt-column-order:before, table.dataTable thead > tr > td.dt-orderable-desc span.dt-column-order:after, table.dataTable thead > tr > td.dt-ordering-asc span.dt-column-order:before, table.dataTable thead > tr > td.dt-ordering-asc span.dt-column-order:after, table.dataTable thead > tr > td.dt-ordering-desc span.dt-column-order:before, table.dataTable thead > tr > td.dt-ordering-desc span.dt-column-order:after {
  opacity: .125;
  font-size: .8em;
  line-height: 9px;
  left: 0;
}

table.dataTable thead > tr > th.dt-orderable-asc, table.dataTable thead > tr > th.dt-orderable-desc, table.dataTable thead > tr > td.dt-orderable-asc, table.dataTable thead > tr > td.dt-orderable-desc {
  cursor: pointer;
}

table.dataTable thead > tr > th.dt-orderable-asc:hover, table.dataTable thead > tr > th.dt-orderable-desc:hover, table.dataTable thead > tr > td.dt-orderable-asc:hover, table.dataTable thead > tr > td.dt-orderable-desc:hover {
  outline-offset: -2px;
  outline: 2px solid #0000000d;
}

table.dataTable thead > tr > th.dt-ordering-asc span.dt-column-order:before, table.dataTable thead > tr > th.dt-ordering-desc span.dt-column-order:after, table.dataTable thead > tr > td.dt-ordering-asc span.dt-column-order:before, table.dataTable thead > tr > td.dt-ordering-desc span.dt-column-order:after {
  opacity: .6;
}

table.dataTable thead > tr > th.sorting_desc_disabled span.dt-column-order:after, table.dataTable thead > tr > th.sorting_asc_disabled span.dt-column-order:before, table.dataTable thead > tr > td.sorting_desc_disabled span.dt-column-order:after, table.dataTable thead > tr > td.sorting_asc_disabled span.dt-column-order:before {
  display: none;
}

table.dataTable thead > tr > th:active, table.dataTable thead > tr > td:active {
  outline: none;
}

div.dt-scroll-body > table.dataTable > thead > tr > th, div.dt-scroll-body > table.dataTable > thead > tr > td {
  overflow: hidden;
}

:root.dark table.dataTable thead > tr > th.dt-orderable-asc:hover, :root.dark table.dataTable thead > tr > th.dt-orderable-desc:hover, :root.dark table.dataTable thead > tr > td.dt-orderable-asc:hover, :root.dark table.dataTable thead > tr > td.dt-orderable-desc:hover, :root[data-bs-theme="dark"] table.dataTable thead > tr > th.dt-orderable-asc:hover, :root[data-bs-theme="dark"] table.dataTable thead > tr > th.dt-orderable-desc:hover, :root[data-bs-theme="dark"] table.dataTable thead > tr > td.dt-orderable-asc:hover, :root[data-bs-theme="dark"] table.dataTable thead > tr > td.dt-orderable-desc:hover {
  outline: 2px solid #ffffff0d;
}

div.dt-processing {
  text-align: center;
  z-index: 10;
  width: 200px;
  margin-top: -22px;
  margin-left: -100px;
  padding: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
}

div.dt-processing > div:last-child {
  width: 80px;
  height: 15px;
  margin: 1em auto;
  position: relative;
}

div.dt-processing > div:last-child > div {
  background: #0089b6;
  background: rgb(var(--dt-row-selected));
  border-radius: 50%;
  width: 13px;
  height: 13px;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  position: absolute;
  top: 0;
}

div.dt-processing > div:last-child > div:first-child {
  animation: .6s infinite datatables-loader-1;
  left: 8px;
}

div.dt-processing > div:last-child > div:nth-child(2) {
  animation: .6s infinite datatables-loader-2;
  left: 8px;
}

div.dt-processing > div:last-child > div:nth-child(3) {
  animation: .6s infinite datatables-loader-2;
  left: 32px;
}

div.dt-processing > div:last-child > div:nth-child(4) {
  animation: .6s infinite datatables-loader-3;
  left: 56px;
}

@keyframes datatables-loader-1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes datatables-loader-3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes datatables-loader-2 {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(24px);
  }
}

table.dataTable.nowrap th, table.dataTable.nowrap td {
  white-space: nowrap;
}

table.dataTable th, table.dataTable td {
  box-sizing: border-box;
}

table.dataTable th.dt-left, table.dataTable td.dt-left {
  text-align: left;
}

table.dataTable th.dt-center, table.dataTable td.dt-center {
  text-align: center;
}

table.dataTable th.dt-right, table.dataTable td.dt-right {
  text-align: right;
}

table.dataTable th.dt-justify, table.dataTable td.dt-justify {
  text-align: justify;
}

table.dataTable th.dt-nowrap, table.dataTable td.dt-nowrap {
  white-space: nowrap;
}

table.dataTable th.dt-empty, table.dataTable td.dt-empty {
  text-align: center;
  vertical-align: top;
}

table.dataTable th.dt-type-numeric, table.dataTable th.dt-type-date, table.dataTable td.dt-type-numeric, table.dataTable td.dt-type-date {
  text-align: right;
}

table.dataTable thead th, table.dataTable thead td, table.dataTable tfoot th, table.dataTable tfoot td, table.dataTable thead th.dt-head-left, table.dataTable thead td.dt-head-left, table.dataTable tfoot th.dt-head-left, table.dataTable tfoot td.dt-head-left {
  text-align: left;
}

table.dataTable thead th.dt-head-center, table.dataTable thead td.dt-head-center, table.dataTable tfoot th.dt-head-center, table.dataTable tfoot td.dt-head-center {
  text-align: center;
}

table.dataTable thead th.dt-head-right, table.dataTable thead td.dt-head-right, table.dataTable tfoot th.dt-head-right, table.dataTable tfoot td.dt-head-right {
  text-align: right;
}

table.dataTable thead th.dt-head-justify, table.dataTable thead td.dt-head-justify, table.dataTable tfoot th.dt-head-justify, table.dataTable tfoot td.dt-head-justify {
  text-align: justify;
}

table.dataTable thead th.dt-head-nowrap, table.dataTable thead td.dt-head-nowrap, table.dataTable tfoot th.dt-head-nowrap, table.dataTable tfoot td.dt-head-nowrap {
  white-space: nowrap;
}

table.dataTable tbody th.dt-body-left, table.dataTable tbody td.dt-body-left {
  text-align: left;
}

table.dataTable tbody th.dt-body-center, table.dataTable tbody td.dt-body-center {
  text-align: center;
}

table.dataTable tbody th.dt-body-right, table.dataTable tbody td.dt-body-right {
  text-align: right;
}

table.dataTable tbody th.dt-body-justify, table.dataTable tbody td.dt-body-justify {
  text-align: justify;
}

table.dataTable tbody th.dt-body-nowrap, table.dataTable tbody td.dt-body-nowrap {
  white-space: nowrap;
}

table.dataTable {
  clear: both;
  width: 100%;
  max-width: none !important;
  margin: 0 !important;
}

table.dataTable tr:nth-child(2n), table.dataTable > tbody > tr {
  background-color: #0000;
}

table.dataTable > tbody > tr.selected > * {
  box-shadow: inset 0 0 0 9999px #0089b6;
  box-shadow: inset 0 0 0 9999px rgb(var(--dt-row-selected));
  color: rgb(var(--dt-row-selected-text));
}

table.dataTable > tbody > tr.selected a {
  color: rgb(var(--dt-row-selected-link));
}

table.dataTable > tbody > tr:nth-child(2n) > * {
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-stripe), .054);
}

table.dataTable > tbody > tr:nth-child(2n).selected > * {
  box-shadow: inset 0 0 0 9999px #0089b6f3;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .954);
}

table.dataTable.hover > tbody > tr:hover > * {
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-hover), .074);
}

table.dataTable.hover > tbody > tr.selected:hover > * {
  box-shadow: inset 0 0 0 9999px #0089b6f8;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .974);
}

div.dt-container div.dt-layout-start > :not(:last-child) {
  margin-right: 1em;
}

div.dt-container div.dt-layout-end > :not(:first-child) {
  margin-left: 1em;
}

div.dt-container div.dt-layout-full {
  width: 100%;
}

div.dt-container div.dt-layout-full > :only-child {
  margin-left: auto;
  margin-right: auto;
}

div.dt-container div.dt-layout-table > div {
  display: block !important;
}

@media screen and (width <= 767px) {
  div.dt-container div.dt-layout-start > :not(:last-child) {
    margin-right: 0;
  }

  div.dt-container div.dt-layout-end > :not(:first-child) {
    margin-left: 0;
  }

  div.dt-container > div.grid-x:not(.dt-layout-table) {
    margin-bottom: 0 !important;
  }

  div.dt-container > div.grid-x:not(.dt-layout-table) > div.flex-container {
    text-align: center;
    width: 100%;
    margin: 5px auto;
    display: block !important;
  }
}

div.dt-container {
  position: relative;
}

div.dt-container div.grid-x {
  margin-bottom: .75rem;
}

div.dt-container div.grid-x:last-child {
  margin-bottom: 0;
}

div.dt-container div.dt-length label {
  text-align: left;
  margin-bottom: 0;
  display: inline;
}

div.dt-container div.dt-length select {
  width: 75px;
  margin-bottom: 0;
}

div.dt-container div.dt-search label {
  margin-bottom: 0;
  display: inline;
}

div.dt-container div.dt-search input {
  margin-bottom: 0;
  margin-left: .5em;
  width: auto !important;
  display: inline-block !important;
}

div.dt-container div.dt-paging ul.pagination {
  margin: 0;
}

div.dt-container div.dt-processing {
  text-align: center;
  width: 200px;
  margin-top: -26px;
  margin-left: -100px;
  padding: 1rem 0;
  position: absolute;
  top: 50%;
  left: 50%;
}

div.dt-scroll-head table {
  margin-bottom: 0 !important;
}

div.dt-scroll-body {
  border-bottom: 1px solid #f1f1f1;
}

div.dt-scroll-body table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

div.dt-scroll-body table tbody {
  border-bottom-width: 0;
}

div.dt-scroll-body table tbody tr:first-child th, div.dt-scroll-body table tbody tr:first-child td {
  border-top: none;
}

div.dt-scroll-foot table {
  border-top: none;
  margin-top: 0 !important;
}

table.dataTable.fixedHeader-floating, table.dataTable.fixedHeader-locked {
  background-color: #fff;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  position: relative !important;
}

@media print {
  table.fixedHeader-floating {
    display: none;
  }
}

table.dataTable thead tr > .dtfc-fixed-start, table.dataTable thead tr > .dtfc-fixed-end, table.dataTable tfoot tr > .dtfc-fixed-start, table.dataTable tfoot tr > .dtfc-fixed-end {
  z-index: 3;
  background-color: #f8f8f8;
  top: 0;
  bottom: 0;
}

table.dataTable tbody tr > .dtfc-fixed-start, table.dataTable tbody tr > .dtfc-fixed-end {
  z-index: 1;
  background-color: #fff;
}

table.dataTable tr > .dtfc-fixed-left:after, table.dataTable tr > .dtfc-fixed-right:after {
  content: "";
  pointer-events: none;
  width: 10px;
  transition: box-shadow .3s;
  position: absolute;
  top: 0;
  bottom: 0;
}

table.dataTable tr > .dtfc-fixed-left:after {
  right: 0;
  transform: translateX(100%);
}

table.dataTable tr > .dtfc-fixed-right:after {
  left: 0;
  transform: translateX(-80%);
}

table.dataTable.dtfc-scrolling-left tr > .dtfc-fixed-left:after {
  box-shadow: inset 10px 0 8px -8px #0003;
}

table.dataTable.dtfc-scrolling-right tr > .dtfc-fixed-right:after {
  box-shadow: inset -10px 0 8px -8px #0003;
}

table.dataTable.dtfc-scrolling-right tr > .dtfc-fixed-right + .dtfc-fixed-right:after {
  box-shadow: none;
}

div.dt-scroll, div.dtfh-floatingparent {
  position: relative;
}

div.dt-scroll div.dtfc-top-blocker, div.dt-scroll div.dtfc-bottom-blocker, div.dtfh-floatingparent div.dtfc-top-blocker, div.dtfh-floatingparent div.dtfc-bottom-blocker {
  background-color: #f8f8f8;
  position: absolute;
}

html.dark table.dataTable thead tr > .dtfc-fixed-start, html.dark table.dataTable thead tr > .dtfc-fixed-end, html.dark table.dataTable tfoot tr > .dtfc-fixed-start, html.dark table.dataTable tfoot tr > .dtfc-fixed-end, html.dark table.dataTable tbody tr > .dtfc-fixed-start, html.dark table.dataTable tbody tr > .dtfc-fixed-end {
  background-color: var(--dt-html-background);
}

html.dark table.dataTable.dtfc-scrolling-left tbody > tr > .dtfc-fixed-left:after {
  box-shadow: inset 10px 0 8px -8px #0000004d;
}

html.dark table.dataTable.dtfc-scrolling-right tbody > tr > .dtfc-fixed-right:after {
  box-shadow: inset -10px 0 8px -8px #0000004d;
}

html.dark table.dataTable.dtfc-scrolling-right tbody > tr > .dtfc-fixed-right + .dtfc-fixed-right:after {
  box-shadow: none;
}

html.dark div.dtfc-top-blocker, html.dark div.dtfc-bottom-blocker {
  background-color: var(--dt-html-background);
}

table.dataTable {
  border-collapse: separate;
  border-spacing: inherit;
}

table.dataTable.table-bordered {
  border-bottom-width: 0;
  border-left-width: 0;
}

table.dataTable.table-bordered th, table.dataTable.table-bordered td {
  border-top-width: 0;
  border-right-width: 0;
}

@media print, screen and (width >= 40em) {
  .reveal.large, .reveal.small, .reveal.tiny, .reveal {
    margin: 0 auto;
    left: auto;
    right: auto;
  }
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-family: sans-serif;
  line-height: 1.15;
}

body {
  margin: 0;
}

article, aside, footer, header, nav, section {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

figcaption, figure {
  display: block;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

main {
  display: block;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
}

a:active, a:hover {
  outline-width: 0;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: inherit;
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  color: #000;
  background-color: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

audio, video {
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

input {
  overflow: visible;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
  display: inline-block;
}

textarea {
  overflow: auto;
}

details {
  display: block;
}

summary {
  display: list-item;
}

menu {
  display: block;
}

canvas {
  display: inline-block;
}

template, [hidden] {
  display: none;
}

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em";
}

html {
  box-sizing: border-box;
  font-size: 14px;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  margin: 0;
  padding: 0;
  font-family: Raleway, Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
}

img {
  vertical-align: middle;
  -ms-interpolation-mode: bicubic;
  max-width: 100%;
  height: auto;
  display: inline-block;
}

textarea {
  border-radius: 0;
  height: auto;
  min-height: 50px;
}

select {
  box-sizing: border-box;
  border-radius: 0;
  width: 100%;
}

.map_canvas img, .map_canvas embed, .map_canvas object, .mqa-display img, .mqa-display embed, .mqa-display object {
  max-width: none !important;
}

button {
  appearance: none;
  cursor: auto;
  background: none;
  border: 0;
  border-radius: 0;
  padding: 0;
  line-height: 1;
}

[data-whatinput="mouse"] button {
  outline: 0;
}

pre {
  overflow: auto;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
}

.is-visible {
  display: block !important;
}

.is-hidden {
  display: none !important;
}

.row {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.row:before, .row:after {
  content: " ";
  display: table;
}

.row:after {
  clear: both;
}

.row.collapse > .column, .row.collapse > .columns {
  padding-left: 0;
  padding-right: 0;
}

.row .row {
  margin-left: -.714286rem;
  margin-right: -.714286rem;
}

@media print, screen and (width >= 40em) {
  .row .row {
    margin-left: -.714286rem;
    margin-right: -.714286rem;
  }
}

@media print, screen and (width >= 64em) {
  .row .row {
    margin-left: -.714286rem;
    margin-right: -.714286rem;
  }
}

.row .row.collapse {
  margin-left: 0;
  margin-right: 0;
}

.row.expanded {
  max-width: none;
}

.row.expanded .row {
  margin-left: auto;
  margin-right: auto;
}

.row:not(.expanded) .row {
  max-width: none;
}

.column, .columns {
  float: left;
  width: 100%;
  padding-left: .714286rem;
  padding-right: .714286rem;
}

.column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
  float: right;
}

.column.end:last-child:last-child, .end.columns:last-child:last-child {
  float: left;
}

.column.row.row, .row.row.columns {
  float: none;
}

.row .column.row.row, .row .row.row.columns {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

.small-1 {
  width: 10%;
}

.small-push-1 {
  position: relative;
  left: 10%;
}

.small-pull-1 {
  position: relative;
  left: -10%;
}

.small-offset-0 {
  margin-left: 0%;
}

.small-2 {
  width: 20%;
}

.small-push-2 {
  position: relative;
  left: 20%;
}

.small-pull-2 {
  position: relative;
  left: -20%;
}

.small-offset-1 {
  margin-left: 10%;
}

.small-3 {
  width: 30%;
}

.small-push-3 {
  position: relative;
  left: 30%;
}

.small-pull-3 {
  position: relative;
  left: -30%;
}

.small-offset-2 {
  margin-left: 20%;
}

.small-4 {
  width: 40%;
}

.small-push-4 {
  position: relative;
  left: 40%;
}

.small-pull-4 {
  position: relative;
  left: -40%;
}

.small-offset-3 {
  margin-left: 30%;
}

.small-5 {
  width: 50%;
}

.small-push-5 {
  position: relative;
  left: 50%;
}

.small-pull-5 {
  position: relative;
  left: -50%;
}

.small-offset-4 {
  margin-left: 40%;
}

.small-6 {
  width: 60%;
}

.small-push-6 {
  position: relative;
  left: 60%;
}

.small-pull-6 {
  position: relative;
  left: -60%;
}

.small-offset-5 {
  margin-left: 50%;
}

.small-7 {
  width: 70%;
}

.small-push-7 {
  position: relative;
  left: 70%;
}

.small-pull-7 {
  position: relative;
  left: -70%;
}

.small-offset-6 {
  margin-left: 60%;
}

.small-8 {
  width: 80%;
}

.small-push-8 {
  position: relative;
  left: 80%;
}

.small-pull-8 {
  position: relative;
  left: -80%;
}

.small-offset-7 {
  margin-left: 70%;
}

.small-9 {
  width: 90%;
}

.small-push-9 {
  position: relative;
  left: 90%;
}

.small-pull-9 {
  position: relative;
  left: -90%;
}

.small-offset-8 {
  margin-left: 80%;
}

.small-10 {
  width: 100%;
}

.small-offset-9 {
  margin-left: 90%;
}

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%;
}

.small-up-1 > .column:nth-of-type(n), .small-up-1 > .columns:nth-of-type(n) {
  clear: none;
}

.small-up-1 > .column:nth-of-type(n+1), .small-up-1 > .columns:nth-of-type(n+1) {
  clear: both;
}

.small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
  float: left;
}

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%;
}

.small-up-2 > .column:nth-of-type(n), .small-up-2 > .columns:nth-of-type(n) {
  clear: none;
}

.small-up-2 > .column:nth-of-type(odd), .small-up-2 > .columns:nth-of-type(odd) {
  clear: both;
}

.small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
  float: left;
}

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.3333%;
}

.small-up-3 > .column:nth-of-type(n), .small-up-3 > .columns:nth-of-type(n) {
  clear: none;
}

.small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
  clear: both;
}

.small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
  float: left;
}

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%;
}

.small-up-4 > .column:nth-of-type(n), .small-up-4 > .columns:nth-of-type(n) {
  clear: none;
}

.small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
  clear: both;
}

.small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
  float: left;
}

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%;
}

.small-up-5 > .column:nth-of-type(n), .small-up-5 > .columns:nth-of-type(n) {
  clear: none;
}

.small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
  clear: both;
}

.small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
  float: left;
}

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.6667%;
}

.small-up-6 > .column:nth-of-type(n), .small-up-6 > .columns:nth-of-type(n) {
  clear: none;
}

.small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
  clear: both;
}

.small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
  float: left;
}

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.2857%;
}

.small-up-7 > .column:nth-of-type(n), .small-up-7 > .columns:nth-of-type(n) {
  clear: none;
}

.small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
  clear: both;
}

.small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
  float: left;
}

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%;
}

.small-up-8 > .column:nth-of-type(n), .small-up-8 > .columns:nth-of-type(n) {
  clear: none;
}

.small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
  clear: both;
}

.small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
  float: left;
}

.small-collapse > .column, .small-collapse > .columns {
  padding-left: 0;
  padding-right: 0;
}

.small-collapse .row, .expanded.row .small-collapse.row {
  margin-left: 0;
  margin-right: 0;
}

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-left: .714286rem;
  padding-right: .714286rem;
}

.small-centered {
  margin-left: auto;
  margin-right: auto;
}

.small-centered, .small-centered:last-child:not(:first-child) {
  float: none;
  clear: both;
}

.small-uncentered, .small-push-0, .small-pull-0 {
  float: left;
  margin-left: 0;
  margin-right: 0;
  position: static;
}

@media print, screen and (width >= 40em) {
  .medium-1 {
    width: 10%;
  }

  .medium-push-1 {
    position: relative;
    left: 10%;
  }

  .medium-pull-1 {
    position: relative;
    left: -10%;
  }

  .medium-offset-0 {
    margin-left: 0%;
  }

  .medium-2 {
    width: 20%;
  }

  .medium-push-2 {
    position: relative;
    left: 20%;
  }

  .medium-pull-2 {
    position: relative;
    left: -20%;
  }

  .medium-offset-1 {
    margin-left: 10%;
  }

  .medium-3 {
    width: 30%;
  }

  .medium-push-3 {
    position: relative;
    left: 30%;
  }

  .medium-pull-3 {
    position: relative;
    left: -30%;
  }

  .medium-offset-2 {
    margin-left: 20%;
  }

  .medium-4 {
    width: 40%;
  }

  .medium-push-4 {
    position: relative;
    left: 40%;
  }

  .medium-pull-4 {
    position: relative;
    left: -40%;
  }

  .medium-offset-3 {
    margin-left: 30%;
  }

  .medium-5 {
    width: 50%;
  }

  .medium-push-5 {
    position: relative;
    left: 50%;
  }

  .medium-pull-5 {
    position: relative;
    left: -50%;
  }

  .medium-offset-4 {
    margin-left: 40%;
  }

  .medium-6 {
    width: 60%;
  }

  .medium-push-6 {
    position: relative;
    left: 60%;
  }

  .medium-pull-6 {
    position: relative;
    left: -60%;
  }

  .medium-offset-5 {
    margin-left: 50%;
  }

  .medium-7 {
    width: 70%;
  }

  .medium-push-7 {
    position: relative;
    left: 70%;
  }

  .medium-pull-7 {
    position: relative;
    left: -70%;
  }

  .medium-offset-6 {
    margin-left: 60%;
  }

  .medium-8 {
    width: 80%;
  }

  .medium-push-8 {
    position: relative;
    left: 80%;
  }

  .medium-pull-8 {
    position: relative;
    left: -80%;
  }

  .medium-offset-7 {
    margin-left: 70%;
  }

  .medium-9 {
    width: 90%;
  }

  .medium-push-9 {
    position: relative;
    left: 90%;
  }

  .medium-pull-9 {
    position: relative;
    left: -90%;
  }

  .medium-offset-8 {
    margin-left: 80%;
  }

  .medium-10 {
    width: 100%;
  }

  .medium-offset-9 {
    margin-left: 90%;
  }

  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%;
  }

  .medium-up-1 > .column:nth-of-type(n), .medium-up-1 > .columns:nth-of-type(n) {
    clear: none;
  }

  .medium-up-1 > .column:nth-of-type(n+1), .medium-up-1 > .columns:nth-of-type(n+1) {
    clear: both;
  }

  .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
    float: left;
  }

  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%;
  }

  .medium-up-2 > .column:nth-of-type(n), .medium-up-2 > .columns:nth-of-type(n) {
    clear: none;
  }

  .medium-up-2 > .column:nth-of-type(odd), .medium-up-2 > .columns:nth-of-type(odd) {
    clear: both;
  }

  .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
    float: left;
  }

  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.3333%;
  }

  .medium-up-3 > .column:nth-of-type(n), .medium-up-3 > .columns:nth-of-type(n) {
    clear: none;
  }

  .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
    clear: both;
  }

  .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
    float: left;
  }

  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%;
  }

  .medium-up-4 > .column:nth-of-type(n), .medium-up-4 > .columns:nth-of-type(n) {
    clear: none;
  }

  .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
    clear: both;
  }

  .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
    float: left;
  }

  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%;
  }

  .medium-up-5 > .column:nth-of-type(n), .medium-up-5 > .columns:nth-of-type(n) {
    clear: none;
  }

  .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
    clear: both;
  }

  .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
    float: left;
  }

  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.6667%;
  }

  .medium-up-6 > .column:nth-of-type(n), .medium-up-6 > .columns:nth-of-type(n) {
    clear: none;
  }

  .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
    clear: both;
  }

  .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
    float: left;
  }

  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.2857%;
  }

  .medium-up-7 > .column:nth-of-type(n), .medium-up-7 > .columns:nth-of-type(n) {
    clear: none;
  }

  .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
    clear: both;
  }

  .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
    float: left;
  }

  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%;
  }

  .medium-up-8 > .column:nth-of-type(n), .medium-up-8 > .columns:nth-of-type(n) {
    clear: none;
  }

  .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
    clear: both;
  }

  .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
    float: left;
  }

  .medium-collapse > .column, .medium-collapse > .columns {
    padding-left: 0;
    padding-right: 0;
  }

  .medium-collapse .row, .expanded.row .medium-collapse.row {
    margin-left: 0;
    margin-right: 0;
  }

  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-left: .714286rem;
    padding-right: .714286rem;
  }

  .medium-centered {
    margin-left: auto;
    margin-right: auto;
  }

  .medium-centered, .medium-centered:last-child:not(:first-child) {
    float: none;
    clear: both;
  }

  .medium-uncentered, .medium-push-0, .medium-pull-0 {
    float: left;
    margin-left: 0;
    margin-right: 0;
    position: static;
  }
}

@media print, screen and (width >= 64em) {
  .large-1 {
    width: 10%;
  }

  .large-push-1 {
    position: relative;
    left: 10%;
  }

  .large-pull-1 {
    position: relative;
    left: -10%;
  }

  .large-offset-0 {
    margin-left: 0%;
  }

  .large-2 {
    width: 20%;
  }

  .large-push-2 {
    position: relative;
    left: 20%;
  }

  .large-pull-2 {
    position: relative;
    left: -20%;
  }

  .large-offset-1 {
    margin-left: 10%;
  }

  .large-3 {
    width: 30%;
  }

  .large-push-3 {
    position: relative;
    left: 30%;
  }

  .large-pull-3 {
    position: relative;
    left: -30%;
  }

  .large-offset-2 {
    margin-left: 20%;
  }

  .large-4 {
    width: 40%;
  }

  .large-push-4 {
    position: relative;
    left: 40%;
  }

  .large-pull-4 {
    position: relative;
    left: -40%;
  }

  .large-offset-3 {
    margin-left: 30%;
  }

  .large-5 {
    width: 50%;
  }

  .large-push-5 {
    position: relative;
    left: 50%;
  }

  .large-pull-5 {
    position: relative;
    left: -50%;
  }

  .large-offset-4 {
    margin-left: 40%;
  }

  .large-6 {
    width: 60%;
  }

  .large-push-6 {
    position: relative;
    left: 60%;
  }

  .large-pull-6 {
    position: relative;
    left: -60%;
  }

  .large-offset-5 {
    margin-left: 50%;
  }

  .large-7 {
    width: 70%;
  }

  .large-push-7 {
    position: relative;
    left: 70%;
  }

  .large-pull-7 {
    position: relative;
    left: -70%;
  }

  .large-offset-6 {
    margin-left: 60%;
  }

  .large-8 {
    width: 80%;
  }

  .large-push-8 {
    position: relative;
    left: 80%;
  }

  .large-pull-8 {
    position: relative;
    left: -80%;
  }

  .large-offset-7 {
    margin-left: 70%;
  }

  .large-9 {
    width: 90%;
  }

  .large-push-9 {
    position: relative;
    left: 90%;
  }

  .large-pull-9 {
    position: relative;
    left: -90%;
  }

  .large-offset-8 {
    margin-left: 80%;
  }

  .large-10 {
    width: 100%;
  }

  .large-offset-9 {
    margin-left: 90%;
  }

  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%;
  }

  .large-up-1 > .column:nth-of-type(n), .large-up-1 > .columns:nth-of-type(n) {
    clear: none;
  }

  .large-up-1 > .column:nth-of-type(n+1), .large-up-1 > .columns:nth-of-type(n+1) {
    clear: both;
  }

  .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
    float: left;
  }

  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%;
  }

  .large-up-2 > .column:nth-of-type(n), .large-up-2 > .columns:nth-of-type(n) {
    clear: none;
  }

  .large-up-2 > .column:nth-of-type(odd), .large-up-2 > .columns:nth-of-type(odd) {
    clear: both;
  }

  .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
    float: left;
  }

  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.3333%;
  }

  .large-up-3 > .column:nth-of-type(n), .large-up-3 > .columns:nth-of-type(n) {
    clear: none;
  }

  .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
    clear: both;
  }

  .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
    float: left;
  }

  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%;
  }

  .large-up-4 > .column:nth-of-type(n), .large-up-4 > .columns:nth-of-type(n) {
    clear: none;
  }

  .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
    clear: both;
  }

  .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
    float: left;
  }

  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%;
  }

  .large-up-5 > .column:nth-of-type(n), .large-up-5 > .columns:nth-of-type(n) {
    clear: none;
  }

  .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
    clear: both;
  }

  .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
    float: left;
  }

  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.6667%;
  }

  .large-up-6 > .column:nth-of-type(n), .large-up-6 > .columns:nth-of-type(n) {
    clear: none;
  }

  .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
    clear: both;
  }

  .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
    float: left;
  }

  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.2857%;
  }

  .large-up-7 > .column:nth-of-type(n), .large-up-7 > .columns:nth-of-type(n) {
    clear: none;
  }

  .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
    clear: both;
  }

  .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
    float: left;
  }

  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%;
  }

  .large-up-8 > .column:nth-of-type(n), .large-up-8 > .columns:nth-of-type(n) {
    clear: none;
  }

  .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
    clear: both;
  }

  .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
    float: left;
  }

  .large-collapse > .column, .large-collapse > .columns {
    padding-left: 0;
    padding-right: 0;
  }

  .large-collapse .row, .expanded.row .large-collapse.row {
    margin-left: 0;
    margin-right: 0;
  }

  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-left: .714286rem;
    padding-right: .714286rem;
  }

  .large-centered {
    margin-left: auto;
    margin-right: auto;
  }

  .large-centered, .large-centered:last-child:not(:first-child) {
    float: none;
    clear: both;
  }

  .large-uncentered, .large-push-0, .large-pull-0 {
    float: left;
    margin-left: 0;
    margin-right: 0;
    position: static;
  }
}

.column-block {
  margin-bottom: 1.42857rem;
}

.column-block > :last-child {
  margin-bottom: 0;
}

div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

p {
  font-size: inherit;
  text-rendering: optimizelegibility;
  margin-bottom: 1rem;
  line-height: 1.6;
}

em, i {
  font-style: italic;
  line-height: inherit;
}

strong, b {
  font-weight: 700;
  line-height: inherit;
}

small {
  font-size: 80%;
  line-height: inherit;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  color: inherit;
  text-rendering: optimizelegibility;
  font-family: Fjalla One, sans-serif;
  font-style: normal;
  font-weight: 400;
}

h1 small, .h1 small, h2 small, .h2 small, h3 small, .h3 small, h4 small, .h4 small, h5 small, .h5 small, h6 small, .h6 small {
  color: #cfcfcf;
  line-height: 0;
}

h1, .h1 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.42857rem;
  line-height: 1.4;
}

h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 1.4;
}

@media print, screen and (width >= 40em) {
  h1, .h1 {
    font-size: 2rem;
  }

  h2, .h2 {
    font-size: 1.14286rem;
  }

  h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    font-size: 1rem;
  }
}

@media print, screen and (width >= 64em) {
  h1, .h1 {
    font-size: 2.57143rem;
  }

  h2, .h2 {
    font-size: 1.71429rem;
  }

  h3, .h3 {
    font-size: 1.28571rem;
  }

  h4, .h4 {
    font-size: 1.21429rem;
  }

  h5, .h5 {
    font-size: 1.14286rem;
  }

  h6, .h6 {
    font-size: 1.07143rem;
  }
}

a {
  line-height: inherit;
  color: #870707;
  cursor: pointer;
  text-decoration: none;
}

a:hover, a:focus {
  color: #740606;
}

a img {
  border: 0;
}

hr {
  clear: both;
  border: 0;
  border-bottom: 1px solid #333;
  max-width: 1000px;
  height: 0;
  margin: 1em auto 2em;
}

ul, ol, dl {
  margin-bottom: 1rem;
  line-height: 1.6;
  list-style-position: outside;
}

li {
  font-size: inherit;
}

ul {
  margin-left: 1.25rem;
  list-style-type: disc;
}

ol {
  margin-left: 1.25rem;
}

ul ul, ul ol, ol ul, ol ol {
  margin-bottom: 0;
  margin-left: 1.25rem;
}

dl {
  margin-bottom: 1rem;
}

dl dt {
  margin-bottom: .3rem;
  font-weight: 700;
}

blockquote {
  border-left: none;
  margin: 0 0 1rem;
  padding: .642857rem 1.42857rem 0 1.35714rem;
}

blockquote, blockquote p {
  color: #333;
  line-height: 1.6;
}

cite {
  color: #777;
  font-size: .928571rem;
  display: block;
}

cite:before {
  content: "— ";
}

abbr, abbr[title] {
  cursor: help;
  border-bottom: 1px dotted #333;
  text-decoration: none;
}

figure {
  margin: 0;
}

code {
  color: #333;
  background-color: #e8e8e8;
  border: 1px solid #cfcfcf;
  padding: .142857rem .357143rem .0714286rem;
  font-family: Consolas, Liberation Mono, Courier, monospace;
  font-weight: 400;
}

kbd {
  color: #333;
  background-color: #e8e8e8;
  margin: 0;
  padding: .142857rem .285714rem 0;
  font-family: Consolas, Liberation Mono, Courier, monospace;
}

.subheader {
  color: #777;
  margin-top: .2rem;
  margin-bottom: .5rem;
  font-weight: 400;
  line-height: 1.4;
}

.lead {
  font-size: 17.5px;
  line-height: 1.6;
}

.stat {
  font-size: 2.5rem;
  line-height: 1;
}

p + .stat {
  margin-top: -1rem;
}

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

@media print, screen and (width >= 40em) {
  .medium-text-left {
    text-align: left;
  }

  .medium-text-right {
    text-align: right;
  }

  .medium-text-center {
    text-align: center;
  }

  .medium-text-justify {
    text-align: justify;
  }
}

@media print, screen and (width >= 64em) {
  .large-text-left {
    text-align: left;
  }

  .large-text-right {
    text-align: right;
  }

  .large-text-center {
    text-align: center;
  }

  .large-text-justify {
    text-align: justify;
  }
}

.show-for-print {
  display: none !important;
}

@media print {
  * {
    box-shadow: none !important;
    color: #000 !important;
    text-shadow: none !important;
    background: none !important;
  }

  .show-for-print {
    display: block !important;
  }

  .hide-for-print {
    display: none !important;
  }

  table.show-for-print {
    display: table !important;
  }

  thead.show-for-print {
    display: table-header-group !important;
  }

  tbody.show-for-print {
    display: table-row-group !important;
  }

  tr.show-for-print {
    display: table-row !important;
  }

  td.show-for-print, th.show-for-print {
    display: table-cell !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  .ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after {
    content: "";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #777;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: .5cm;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }

  .print-break-inside {
    page-break-inside: auto;
  }
}

.button {
  vertical-align: middle;
  -webkit-appearance: none;
  text-align: center;
  cursor: pointer;
  color: #fff;
  background-color: #870707;
  border: 1px solid #0000;
  border-radius: 0;
  margin: 0 0 1rem;
  padding: 12px 1em;
  font-family: inherit;
  font-size: .9rem;
  line-height: 1;
  transition: background-color .25s ease-out, color .25s ease-out;
  display: inline-block;
}

[data-whatinput="mouse"] .button {
  outline: 0;
}

.button:hover, .button:focus {
  color: #fff;
  background-color: #730606;
}

.button.tiny {
  font-size: .6rem;
}

.button.small {
  font-size: .75rem;
}

.button.large {
  font-size: 1.25rem;
}

.button.expanded {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  display: block;
}

.button.primary {
  color: #fff;
  background-color: #870707;
}

.button.primary:hover, .button.primary:focus {
  color: #fff;
  background-color: #6c0606;
}

.button.secondary {
  color: #fff;
  background-color: #0b1459;
}

.button.secondary:hover, .button.secondary:focus {
  color: #fff;
  background-color: #091047;
}

.button.success {
  color: #fff;
  background-color: #006d1a;
}

.button.success:hover, .button.success:focus {
  color: #fff;
  background-color: #005715;
}

.button.alert {
  color: #fff;
  background-color: #f74545;
}

.button.alert:hover, .button.alert:focus {
  color: #fff;
  background-color: #f20a0a;
}

.button.warning {
  color: #333;
  background-color: #dbab10;
}

.button.warning:hover, .button.warning:focus {
  color: #333;
  background-color: #af890d;
}

.button.menu, .button.menu:hover, .button.menu:focus {
  color: #fff;
  background-color: #000;
}

.button.disabled, .button[disabled] {
  opacity: .25;
  cursor: not-allowed;
}

.button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
  color: #fff;
  background-color: #870707;
}

.button.disabled.primary, .button[disabled].primary {
  opacity: .25;
  cursor: not-allowed;
}

.button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
  color: #fff;
  background-color: #870707;
}

.button.disabled.secondary, .button[disabled].secondary {
  opacity: .25;
  cursor: not-allowed;
}

.button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
  color: #fff;
  background-color: #0b1459;
}

.button.disabled.success, .button[disabled].success {
  opacity: .25;
  cursor: not-allowed;
}

.button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
  color: #fff;
  background-color: #006d1a;
}

.button.disabled.alert, .button[disabled].alert {
  opacity: .25;
  cursor: not-allowed;
}

.button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
  color: #fff;
  background-color: #f74545;
}

.button.disabled.warning, .button[disabled].warning {
  opacity: .25;
  cursor: not-allowed;
}

.button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
  color: #333;
  background-color: #dbab10;
}

.button.disabled.menu, .button[disabled].menu {
  opacity: .25;
  cursor: not-allowed;
}

.button.disabled.menu, .button.disabled.menu:hover, .button.disabled.menu:focus, .button[disabled].menu, .button[disabled].menu:hover, .button[disabled].menu:focus {
  color: #fff;
  background-color: #000;
}

.button.hollow {
  color: #870707;
  border: 1px solid #870707;
}

.button.hollow, .button.hollow:hover, .button.hollow:focus, .button.hollow.disabled, .button.hollow.disabled:hover, .button.hollow.disabled:focus, .button.hollow[disabled], .button.hollow[disabled]:hover, .button.hollow[disabled]:focus {
  background-color: #0000;
}

.button.hollow:hover, .button.hollow:focus {
  color: #440404;
  border-color: #440404;
}

.button.hollow:hover.disabled, .button.hollow:hover[disabled], .button.hollow:focus.disabled, .button.hollow:focus[disabled], .button.hollow.primary {
  color: #870707;
  border: 1px solid #870707;
}

.button.hollow.primary:hover, .button.hollow.primary:focus {
  color: #440404;
  border-color: #440404;
}

.button.hollow.primary:hover.disabled, .button.hollow.primary:hover[disabled], .button.hollow.primary:focus.disabled, .button.hollow.primary:focus[disabled] {
  color: #870707;
  border: 1px solid #870707;
}

.button.hollow.secondary {
  color: #0b1459;
  border: 1px solid #0b1459;
}

.button.hollow.secondary:hover, .button.hollow.secondary:focus {
  color: #060a2d;
  border-color: #060a2d;
}

.button.hollow.secondary:hover.disabled, .button.hollow.secondary:hover[disabled], .button.hollow.secondary:focus.disabled, .button.hollow.secondary:focus[disabled] {
  color: #0b1459;
  border: 1px solid #0b1459;
}

.button.hollow.success {
  color: #006d1a;
  border: 1px solid #006d1a;
}

.button.hollow.success:hover, .button.hollow.success:focus {
  color: #00370d;
  border-color: #00370d;
}

.button.hollow.success:hover.disabled, .button.hollow.success:hover[disabled], .button.hollow.success:focus.disabled, .button.hollow.success:focus[disabled] {
  color: #006d1a;
  border: 1px solid #006d1a;
}

.button.hollow.alert {
  color: #f74545;
  border: 1px solid #f74545;
}

.button.hollow.alert:hover, .button.hollow.alert:focus {
  color: #970707;
  border-color: #970707;
}

.button.hollow.alert:hover.disabled, .button.hollow.alert:hover[disabled], .button.hollow.alert:focus.disabled, .button.hollow.alert:focus[disabled] {
  color: #f74545;
  border: 1px solid #f74545;
}

.button.hollow.warning {
  color: #dbab10;
  border: 1px solid #dbab10;
}

.button.hollow.warning:hover, .button.hollow.warning:focus {
  color: #6e5608;
  border-color: #6e5608;
}

.button.hollow.warning:hover.disabled, .button.hollow.warning:hover[disabled], .button.hollow.warning:focus.disabled, .button.hollow.warning:focus[disabled] {
  color: #dbab10;
  border: 1px solid #dbab10;
}

.button.hollow.menu {
  color: #000;
  border: 1px solid #000;
}

.button.hollow.menu:hover, .button.hollow.menu:focus {
  color: #000;
  border-color: #000;
}

.button.hollow.menu:hover.disabled, .button.hollow.menu:hover[disabled], .button.hollow.menu:focus.disabled, .button.hollow.menu:focus[disabled] {
  color: #000;
  border: 1px solid #000;
}

.button.clear {
  color: #870707;
  border: 1px solid #870707;
}

.button.clear, .button.clear:hover, .button.clear:focus, .button.clear.disabled, .button.clear.disabled:hover, .button.clear.disabled:focus, .button.clear[disabled], .button.clear[disabled]:hover, .button.clear[disabled]:focus {
  background-color: #0000;
}

.button.clear:hover, .button.clear:focus {
  color: #440404;
  border-color: #440404;
}

.button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus.disabled, .button.clear:focus[disabled] {
  color: #870707;
  border: 1px solid #870707;
}

.button.clear, .button.clear.disabled, .button.clear[disabled], .button.clear:hover, .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus, .button.clear:focus.disabled, .button.clear:focus[disabled] {
  border-color: #0000;
}

.button.clear.primary {
  color: #870707;
  border: 1px solid #870707;
}

.button.clear.primary:hover, .button.clear.primary:focus {
  color: #440404;
  border-color: #440404;
}

.button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
  color: #870707;
  border: 1px solid #870707;
}

.button.clear.primary, .button.clear.primary.disabled, .button.clear.primary[disabled], .button.clear.primary:hover, .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus, .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
  border-color: #0000;
}

.button.clear.secondary {
  color: #0b1459;
  border: 1px solid #0b1459;
}

.button.clear.secondary:hover, .button.clear.secondary:focus {
  color: #060a2d;
  border-color: #060a2d;
}

.button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
  color: #0b1459;
  border: 1px solid #0b1459;
}

.button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary:hover, .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus, .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
  border-color: #0000;
}

.button.clear.success {
  color: #006d1a;
  border: 1px solid #006d1a;
}

.button.clear.success:hover, .button.clear.success:focus {
  color: #00370d;
  border-color: #00370d;
}

.button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
  color: #006d1a;
  border: 1px solid #006d1a;
}

.button.clear.success, .button.clear.success.disabled, .button.clear.success[disabled], .button.clear.success:hover, .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus, .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
  border-color: #0000;
}

.button.clear.alert {
  color: #f74545;
  border: 1px solid #f74545;
}

.button.clear.alert:hover, .button.clear.alert:focus {
  color: #970707;
  border-color: #970707;
}

.button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
  color: #f74545;
  border: 1px solid #f74545;
}

.button.clear.alert, .button.clear.alert.disabled, .button.clear.alert[disabled], .button.clear.alert:hover, .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus, .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
  border-color: #0000;
}

.button.clear.warning {
  color: #dbab10;
  border: 1px solid #dbab10;
}

.button.clear.warning:hover, .button.clear.warning:focus {
  color: #6e5608;
  border-color: #6e5608;
}

.button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
  color: #dbab10;
  border: 1px solid #dbab10;
}

.button.clear.warning, .button.clear.warning.disabled, .button.clear.warning[disabled], .button.clear.warning:hover, .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus, .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
  border-color: #0000;
}

.button.clear.menu {
  color: #000;
  border: 1px solid #000;
}

.button.clear.menu:hover, .button.clear.menu:focus {
  color: #000;
  border-color: #000;
}

.button.clear.menu:hover.disabled, .button.clear.menu:hover[disabled], .button.clear.menu:focus.disabled, .button.clear.menu:focus[disabled] {
  color: #000;
  border: 1px solid #000;
}

.button.clear.menu, .button.clear.menu.disabled, .button.clear.menu[disabled], .button.clear.menu:hover, .button.clear.menu:hover.disabled, .button.clear.menu:hover[disabled], .button.clear.menu:focus, .button.clear.menu:focus.disabled, .button.clear.menu:focus[disabled] {
  border-color: #0000;
}

.button.dropdown:after {
  content: "";
  float: right;
  border: .4em inset #0000;
  border-top: .4em solid #fff;
  border-bottom-width: 0;
  width: 0;
  height: 0;
  margin-left: 1em;
  display: inline-block;
  position: relative;
  top: .4em;
}

.button.dropdown.hollow:after, .button.dropdown.hollow.primary:after {
  border-top-color: #870707;
}

.button.dropdown.hollow.secondary:after {
  border-top-color: #0b1459;
}

.button.dropdown.hollow.success:after {
  border-top-color: #006d1a;
}

.button.dropdown.hollow.alert:after {
  border-top-color: #f74545;
}

.button.dropdown.hollow.warning:after {
  border-top-color: #dbab10;
}

.button.dropdown.hollow.menu:after {
  border-top-color: #000;
}

.button.arrow-only:after {
  float: none;
  margin-left: 0;
  top: -.1em;
}

a.button:hover, a.button:focus {
  text-decoration: none;
}

[type="text"], [type="password"], [type="date"], [type="datetime"], [type="datetime-local"], [type="month"], [type="week"], [type="email"], [type="number"], [type="search"], [type="tel"], [type="time"], [type="url"], [type="color"], textarea {
  box-sizing: border-box;
  color: #333;
  appearance: none;
  background-color: #fff;
  border: 1px solid #cfcfcf;
  border-radius: 0;
  width: 100%;
  height: 2.78571rem;
  margin: 0 0 1.14286rem;
  padding: .571429rem;
  font-family: inherit;
  font-size: 1.14286rem;
  font-weight: 400;
  line-height: 1.5;
  transition: box-shadow .5s, border-color .25s ease-in-out;
  display: block;
  box-shadow: inset 0 1px 2px #3333331a;
}

[type="text"]:focus, [type="password"]:focus, [type="date"]:focus, [type="datetime"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="week"]:focus, [type="email"]:focus, [type="number"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="url"]:focus, [type="color"]:focus, textarea:focus {
  background-color: #fff;
  border: 1px solid #006d1a;
  outline: none;
  transition: box-shadow .5s, border-color .25s ease-in-out;
  box-shadow: 0 0 5px #006d1a;
}

textarea {
  max-width: 100%;
}

textarea[rows] {
  height: auto;
}

input::placeholder, textarea::placeholder {
  color: #cfcfcf;
}

input:disabled, input[readonly], textarea:disabled, textarea[readonly] {
  cursor: not-allowed;
  background-color: #e8e8e8;
}

[type="submit"], [type="button"] {
  appearance: none;
  border-radius: 0;
}

input[type="search"] {
  box-sizing: border-box;
}

[type="file"], [type="checkbox"], [type="radio"] {
  margin: 0 0 1.14286rem;
}

[type="checkbox"] + label, [type="radio"] + label {
  vertical-align: baseline;
  margin-bottom: 0;
  margin-left: .571429rem;
  margin-right: 1.14286rem;
  display: inline-block;
}

[type="checkbox"] + label[for], [type="radio"] + label[for] {
  cursor: pointer;
}

label > [type="checkbox"], label > [type="radio"] {
  margin-right: .571429rem;
}

[type="file"] {
  width: 100%;
}

label {
  color: #333;
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.8;
  display: block;
}

label.middle {
  margin: 0 0 1.14286rem;
  padding: .642857rem 0;
}

.help-text {
  color: #333;
  margin-top: -.571429rem;
  font-size: .928571rem;
  font-style: italic;
}

.input-group {
  width: 100%;
  margin-bottom: 1.14286rem;
  display: table;
}

.input-group > :first-child, .input-group > :last-child > * {
  border-radius: 0;
}

.input-group-button a, .input-group-button input, .input-group-button button, .input-group-button label, .input-group-button, .input-group-field, .input-group-label {
  white-space: nowrap;
  vertical-align: middle;
  margin: 0;
  display: table-cell;
}

.input-group-label {
  color: #333;
  text-align: center;
  white-space: nowrap;
  background: #e8e8e8;
  border: 1px solid #cfcfcf;
  width: 1%;
  height: 100%;
  padding: 0 1rem;
}

.input-group-label:first-child {
  border-right: 0;
}

.input-group-label:last-child {
  border-left: 0;
}

.input-group-field {
  border-radius: 0;
  height: 2.85714rem;
}

.input-group-button {
  text-align: center;
  width: 1%;
  height: 100%;
  padding-top: 0;
  padding-bottom: 0;
}

.input-group-button a, .input-group-button input, .input-group-button button, .input-group-button label {
  height: 2.85714rem;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1.14286rem;
}

.input-group .input-group-button {
  display: table-cell;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  max-width: 100%;
  margin-bottom: .571429rem;
}

.fieldset {
  border: 1px solid #cfcfcf;
  margin: 1.28571rem 0;
  padding: 1.42857rem;
}

.fieldset legend {
  margin: 0 0 0 -.214286rem;
  padding: 0 .214286rem;
}

select {
  appearance: none;
  color: #333;
  background-color: #fff;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28119, 119, 119%29'></polygon></svg>");
  background-position: right -1.14286rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  background-origin: content-box;
  border: 1px solid #cfcfcf;
  border-radius: 0;
  height: 2.78571rem;
  margin: 0 0 1.14286rem;
  padding: .571429rem 1.71429rem .571429rem .571429rem;
  font-family: inherit;
  font-size: 1.14286rem;
  font-weight: 400;
  line-height: 1.5;
  transition: box-shadow .5s, border-color .25s ease-in-out;
}

select:disabled {
  cursor: not-allowed;
  background-color: #e8e8e8;
}

select::-ms-expand {
  display: none;
}

select[multiple] {
  background-image: none;
  height: auto;
}

.is-invalid-input:not(:focus) {
  background-color: #feecec;
  border-color: #f74545;
}

.is-invalid-input:not(:focus)::placeholder, .is-invalid-label {
  color: #f74545;
}

.form-error {
  color: #f74545;
  margin-top: -.571429rem;
  margin-bottom: 1.14286rem;
  font-size: .857143rem;
  font-weight: 700;
  display: none;
}

.form-error.is-visible {
  display: block;
}

input[type="range"] {
  appearance: none;
  cursor: pointer;
  border: 0;
  width: 100%;
  height: auto;
  margin-top: .45rem;
  margin-bottom: .45rem;
  line-height: 1;
  display: block;
}

input[type="range"]:focus {
  outline: 0;
}

input[type="range"][disabled] {
  opacity: .25;
}

input[type="range"]::-webkit-slider-runnable-track {
  background: #e8e8e8;
  height: .5rem;
}

input[type="range"]::-webkit-slider-handle {
  -webkit-appearance: none;
  background: #870707;
  width: 1.4rem;
  height: 1.4rem;
  margin-top: -.45rem;
}

input[type="range"]::-moz-range-track {
  -moz-appearance: none;
  background: #e8e8e8;
  height: .5rem;
}

input[type="range"]::-moz-range-thumb {
  -moz-appearance: none;
  background: #870707;
  width: 1.4rem;
  height: 1.4rem;
  margin-top: -.45rem;
}

input[type="range"]::-ms-track {
  color: #0000;
  background: #e8e8e8;
  border: .45rem solid #fff;
  border-left: 0;
  border-right: 0;
  height: .5rem;
  overflow: visible;
}

input[type="range"]::-ms-thumb {
  background: #870707;
  border: 0;
  width: 1.4rem;
  height: 1.4rem;
}

input[type="range"]::-ms-fill-lower {
  background: #cfcfcf;
}

input[type="range"]::-ms-fill-upper {
  background: #e8e8e8;
}

output {
  vertical-align: middle;
  margin-left: .5em;
  line-height: 1.4rem;
}

.accordion {
  background: #fff;
  margin-left: 0;
  list-style-type: none;
}

.accordion[disabled] .accordion-title {
  cursor: not-allowed;
}

.accordion-item:first-child > :first-child, .accordion-item:last-child > :last-child {
  border-radius: 0;
}

.accordion-title {
  color: #870707;
  border: none;
  border-bottom-width: 0;
  padding: 1.25rem 1rem;
  font-size: .857143rem;
  line-height: 1;
  display: block;
  position: relative;
}

:last-child:not(.is-active) > .accordion-title {
  border-bottom: none;
  border-radius: 0;
}

.accordion-title:hover, .accordion-title:focus {
  background-color: inherit;
}

.accordion-content {
  color: #333;
  background-color: #cfcfcf;
  border: none;
  border-bottom-width: 0;
  padding: 1rem;
  display: none;
}

:last-child > .accordion-content:last-child {
  border-bottom: none;
}

.breadcrumbs {
  margin: 0 0 .5rem;
  list-style: none;
}

.breadcrumbs:before, .breadcrumbs:after {
  content: " ";
  display: table;
}

.breadcrumbs:after {
  clear: both;
}

.breadcrumbs li {
  float: left;
  color: #333;
  cursor: default;
  font-size: 14px;
}

.breadcrumbs li:not(:last-child):after {
  opacity: 1;
  content: "|";
  color: #cfcfcf;
  margin: 0 .5rem;
  position: relative;
}

.breadcrumbs a {
  color: #870707;
}

.breadcrumbs a:hover {
  text-decoration: underline;
}

.breadcrumbs .disabled {
  color: #cfcfcf;
  cursor: not-allowed;
}

.button-group {
  margin-bottom: 1rem;
  font-size: 0;
}

.button-group:before, .button-group:after {
  content: " ";
  display: table;
}

.button-group:after {
  clear: both;
}

.button-group .button {
  margin: 0 1px 1px 0;
  font-size: .9rem;
}

.button-group .button:last-child {
  margin-right: 0;
}

.button-group.tiny .button {
  font-size: .6rem;
}

.button-group.small .button {
  font-size: .75rem;
}

.button-group.large .button {
  font-size: 1.25rem;
}

.button-group.expanded {
  margin-right: -1px;
}

.button-group.expanded:before, .button-group.expanded:after {
  display: none;
}

.button-group.expanded .button:first-child:last-child {
  width: 100%;
}

.button-group.expanded .button:first-child:nth-last-child(2), .button-group.expanded .button:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ .button {
  width: calc(50% - 1px);
  margin-right: 1px;
  display: inline-block;
}

.button-group.expanded .button:first-child:nth-last-child(2):last-child, .button-group.expanded .button:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ .button:last-child {
  margin-right: 0;
}

.button-group.expanded .button:first-child:last-child, .button-group.expanded .button:first-child:last-child:first-child:last-child ~ .button {
  width: calc(100% - 1px);
  margin-right: 1px;
  display: inline-block;
}

.button-group.expanded .button:first-child:last-child:last-child, .button-group.expanded .button:first-child:last-child:first-child:last-child ~ .button:last-child {
  margin-right: 0;
}

.button-group.expanded .button:first-child:nth-last-child(0), .button-group.expanded .button:first-child:nth-last-child(0):first-child:nth-last-child(0) ~ .button {
  width: calc(3.40282e38% - 1px);
  margin-right: 1px;
  display: inline-block;
}

.button-group.expanded .button:first-child:nth-last-child(0):last-child, .button-group.expanded .button:first-child:nth-last-child(0):first-child:nth-last-child(0) ~ .button:last-child {
  margin-right: 0;
}

.button-group.primary .button {
  color: #fff;
  background-color: #870707;
}

.button-group.primary .button:hover, .button-group.primary .button:focus {
  color: #fff;
  background-color: #6c0606;
}

.button-group.secondary .button {
  color: #fff;
  background-color: #0b1459;
}

.button-group.secondary .button:hover, .button-group.secondary .button:focus {
  color: #fff;
  background-color: #091047;
}

.button-group.success .button {
  color: #fff;
  background-color: #006d1a;
}

.button-group.success .button:hover, .button-group.success .button:focus {
  color: #fff;
  background-color: #005715;
}

.button-group.alert .button {
  color: #fff;
  background-color: #f74545;
}

.button-group.alert .button:hover, .button-group.alert .button:focus {
  color: #fff;
  background-color: #f20a0a;
}

.button-group.warning .button {
  color: #333;
  background-color: #dbab10;
}

.button-group.warning .button:hover, .button-group.warning .button:focus {
  color: #333;
  background-color: #af890d;
}

.button-group.menu .button, .button-group.menu .button:hover, .button-group.menu .button:focus {
  color: #fff;
  background-color: #000;
}

.button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
  width: 100%;
}

.button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
  margin-bottom: 0;
}

@media print, screen and (width >= 40em) {
  .button-group.stacked-for-small .button {
    width: auto;
    margin-bottom: 0;
  }
}

@media print, screen and (width >= 64em) {
  .button-group.stacked-for-medium .button {
    width: auto;
    margin-bottom: 0;
  }
}

@media screen and (width <= 39.9375em) {
  .button-group.stacked-for-small.expanded {
    display: block;
  }

  .button-group.stacked-for-small.expanded .button {
    margin-right: 0;
    display: block;
  }
}

.callout {
  color: #333;
  background-color: #fff;
  border: 1px solid #33333340;
  border-radius: 0;
  margin: 0 0 1rem;
  padding: 1rem;
  position: relative;
}

.callout > :first-child {
  margin-top: 0;
}

.callout > :last-child {
  margin-bottom: 0;
}

.callout.primary {
  color: #333;
  background-color: #fccbcb;
}

.callout.secondary {
  color: #333;
  background-color: #c8cef8;
}

.callout.success {
  color: #333;
  background-color: #c3ffd1;
}

.callout.alert {
  color: #333;
  background-color: #fee3e3;
}

.callout.warning {
  color: #333;
  background-color: #fcf4d9;
}

.callout.menu {
  color: #333;
  background-color: #d9d9d9;
}

.callout.small {
  padding: .5rem;
}

.callout.large {
  padding: 3rem;
}

.menu {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}

[data-whatinput="mouse"] .menu li {
  outline: 0;
}

.menu a, .menu .button {
  padding: .7rem 1rem;
  line-height: 1;
  text-decoration: none;
  display: block;
}

.menu input, .menu select, .menu a, .menu button {
  margin-bottom: 0;
}

.menu input, .menu li, .menu.horizontal li {
  display: inline-block;
}

.menu.vertical li {
  display: block;
}

.menu.expanded {
  width: 100%;
  display: table;
}

.menu.expanded > li {
  vertical-align: middle;
  display: table-cell;
}

.menu.simple li + li {
  margin-left: 1rem;
}

.menu.simple a {
  padding: 0;
}

@media print, screen and (width >= 40em) {
  .menu.medium-horizontal li {
    display: inline-block;
  }

  .menu.medium-vertical li {
    display: block;
  }

  .menu.medium-expanded {
    width: 100%;
    display: table;
  }

  .menu.medium-expanded > li {
    vertical-align: middle;
    display: table-cell;
  }

  .menu.medium-simple {
    width: 100%;
    display: table;
  }

  .menu.medium-simple > li {
    vertical-align: middle;
    display: table-cell;
  }
}

@media print, screen and (width >= 64em) {
  .menu.large-horizontal li {
    display: inline-block;
  }

  .menu.large-vertical li {
    display: block;
  }

  .menu.large-expanded {
    width: 100%;
    display: table;
  }

  .menu.large-expanded > li {
    vertical-align: middle;
    display: table-cell;
  }

  .menu.large-simple {
    width: 100%;
    display: table;
  }

  .menu.large-simple > li {
    vertical-align: middle;
    display: table-cell;
  }
}

.menu.nested {
  margin-left: 1rem;
  margin-right: 0;
}

.menu.icons img, .menu.icons i, .menu.icons svg, .menu.icons img + span, .menu.icons i + span, .menu.icons svg + span, .menu.icon-top img, .menu.icon-top i, .menu.icon-top svg, .menu.icon-right img, .menu.icon-right i, .menu.icon-right svg, .menu.icon-bottom img, .menu.icon-bottom i, .menu.icon-bottom svg, .menu.icon-left img, .menu.icon-left i, .menu.icon-left svg, .menu.icon-top img + span, .menu.icon-top i + span, .menu.icon-top svg + span, .menu.icon-right img + span, .menu.icon-right i + span, .menu.icon-right svg + span, .menu.icon-bottom img + span, .menu.icon-bottom i + span, .menu.icon-bottom svg + span, .menu.icon-left img + span, .menu.icon-left i + span, .menu.icon-left svg + span {
  vertical-align: middle;
}

.menu.icon-left li a img, .menu.icon-left li a i, .menu.icon-left li a svg {
  margin-right: .25rem;
  display: inline-block;
}

.menu.icon-right li a img, .menu.icon-right li a i, .menu.icon-right li a svg {
  margin-left: .25rem;
  display: inline-block;
}

.menu.icon-top li a {
  text-align: center;
}

.menu.icon-top li a img, .menu.icon-top li a i, .menu.icon-top li a svg {
  margin: 0 auto .25rem;
  display: block;
}

.menu.icon-bottom li a {
  text-align: center;
}

.menu.icon-bottom li a img, .menu.icon-bottom li a i, .menu.icon-bottom li a svg {
  margin: .25rem auto 0;
  display: block;
}

.menu .is-active > a, .menu .active > a {
  color: #fff;
  background: #870707;
}

.menu.align-left {
  text-align: left;
}

.menu.align-right {
  text-align: right;
}

.menu.align-right .submenu li {
  text-align: left;
}

.menu.align-right.vertical .submenu li {
  text-align: right;
}

.menu.align-right .nested {
  margin-left: 0;
  margin-right: 1rem;
}

.menu.align-center {
  text-align: center;
}

.menu.align-center .submenu li {
  text-align: left;
}

.menu .menu-text {
  color: inherit;
  padding: .7rem 1rem;
  font-weight: bold;
  line-height: 1;
}

.menu-centered > .menu {
  text-align: center;
}

.menu-centered > .menu .submenu li {
  text-align: left;
}

.no-js [data-responsive-menu] ul {
  display: none;
}

.is-drilldown {
  position: relative;
  overflow: hidden;
}

.is-drilldown li {
  display: block;
}

.is-drilldown.animate-height {
  transition: height .5s;
}

.drilldown a {
  background: #fff;
  padding: .7rem 1rem;
}

.drilldown .is-drilldown-submenu {
  z-index: -1;
  background: #fff;
  width: 100%;
  transition: transform .25s;
  position: absolute;
  top: 0;
  left: 100%;
}

.drilldown .is-drilldown-submenu.is-active {
  z-index: 1;
  display: block;
  transform: translateX(-100%);
}

.drilldown .is-drilldown-submenu.is-closing {
  transform: translateX(100%);
}

.drilldown .is-drilldown-submenu a {
  padding: .7rem 1rem;
}

.drilldown .nested.is-drilldown-submenu {
  margin-left: 0;
  margin-right: 0;
}

.drilldown .drilldown-submenu-cover-previous {
  min-height: 100%;
}

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  left: auto;
  right: 0;
}

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  left: 0;
  right: auto;
}

.dropdown.menu a {
  padding: .7rem 1rem;
}

[data-whatinput="mouse"] .dropdown.menu a {
  outline: 0;
}

.dropdown.menu .is-active > a {
  color: #870707;
  background: none;
}

.no-js .dropdown.menu ul {
  display: none;
}

.dropdown.menu .nested.is-dropdown-submenu {
  margin-left: 0;
  margin-right: 0;
}

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0;
}

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  top: 0;
  left: auto;
  right: 100%;
}

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  left: 100%;
  right: auto;
}

@media print, screen and (width >= 40em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    left: auto;
    right: 0;
  }

  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    left: 0;
    right: auto;
  }

  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0;
  }

  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    top: 0;
    left: auto;
    right: 100%;
  }

  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    left: 100%;
    right: auto;
  }
}

@media print, screen and (width >= 64em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    left: auto;
    right: 0;
  }

  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    left: 0;
    right: auto;
  }

  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0;
  }

  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    top: 0;
    left: auto;
    right: 100%;
  }

  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    left: 100%;
    right: auto;
  }
}

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  left: auto;
  right: 0;
}

.is-dropdown-menu.vertical {
  width: 100px;
}

.is-dropdown-menu.vertical.align-right {
  float: right;
}

.is-dropdown-submenu-parent {
  position: relative;
}

.is-dropdown-submenu-parent a:after {
  margin-top: -6px;
  position: absolute;
  top: 50%;
  left: auto;
  right: 5px;
}

.is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
  top: 100%;
  left: auto;
}

.is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
  left: auto;
  right: 100%;
}

.is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
  left: 100%;
  right: auto;
}

.is-dropdown-submenu {
  z-index: 1;
  background: #fff;
  border: 1px solid #cfcfcf;
  min-width: 220px;
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
}

.dropdown .is-dropdown-submenu a {
  padding: .7rem 1rem;
}

.is-dropdown-submenu .is-dropdown-submenu {
  margin-top: -1px;
}

.is-dropdown-submenu > li {
  width: 100%;
}

.is-dropdown-submenu.js-dropdown-active {
  display: block;
}

.orbit {
  position: relative;
}

.orbit-container {
  height: 0;
  margin: 0;
  list-style: none;
  position: relative;
  overflow: hidden;
}

.orbit-slide {
  width: 100%;
}

.orbit-slide.no-motionui.is-active {
  top: 0;
  left: 0;
}

.orbit-figure {
  margin: 0;
}

.orbit-image {
  width: 100%;
  max-width: 100%;
  margin: 0;
}

.orbit-caption {
  color: #fff;
  background-color: #3336;
  width: 100%;
  margin-bottom: 0;
  padding: 1rem;
  position: absolute;
  bottom: 0;
}

.orbit-next, .orbit-previous {
  z-index: 10;
  color: #fff;
  padding: 1rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

[data-whatinput="mouse"] .orbit-next, [data-whatinput="mouse"] .orbit-previous {
  outline: 0;
}

.orbit-next:hover, .orbit-previous:hover, .orbit-next:active, .orbit-previous:active, .orbit-next:focus, .orbit-previous:focus {
  background-color: #33333380;
}

.orbit-previous {
  left: 0;
}

.orbit-next {
  left: auto;
  right: 0;
}

.orbit-bullets {
  text-align: center;
  margin-top: .8rem;
  margin-bottom: .8rem;
  position: relative;
}

[data-whatinput="mouse"] .orbit-bullets {
  outline: 0;
}

.orbit-bullets button {
  background-color: #cfcfcf;
  border-radius: 50%;
  width: 1.2rem;
  height: 1.2rem;
  margin: .1rem;
}

.orbit-bullets button:hover, .orbit-bullets button.is-active {
  background-color: #777;
}

.progress {
  background-color: #cfcfcf;
  border-radius: 0;
  height: 1rem;
  margin-bottom: 1rem;
}

.progress.primary .progress-meter {
  background-color: #870707;
}

.progress.secondary .progress-meter {
  background-color: #0b1459;
}

.progress.success .progress-meter {
  background-color: #006d1a;
}

.progress.alert .progress-meter {
  background-color: #f74545;
}

.progress.warning .progress-meter {
  background-color: #dbab10;
}

.progress.menu .progress-meter {
  background-color: #000;
}

.progress-meter {
  background-color: #870707;
  width: 0%;
  height: 100%;
  display: block;
  position: relative;
}

.progress-meter-text {
  color: #fff;
  white-space: nowrap;
  margin: 0;
  font-size: .75rem;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

progress {
  appearance: none;
  background: #cfcfcf;
  border: 0;
  border-radius: 0;
  width: 100%;
  height: 1rem;
  margin-bottom: 1rem;
  display: block;
}

progress::-webkit-progress-bar {
  background: #cfcfcf;
  border-radius: 0;
}

progress::-webkit-progress-value {
  background: #870707;
  border-radius: 0;
}

progress::-moz-progress-bar {
  background: #870707;
  border-radius: 0;
}

progress.primary {
  color: #870707;
}

progress.primary::-webkit-progress-value {
  background: #870707;
}

progress.primary::-moz-progress-bar {
  background: #870707;
}

progress.secondary {
  color: #0b1459;
}

progress.secondary::-webkit-progress-value {
  background: #0b1459;
}

progress.secondary::-moz-progress-bar {
  background: #0b1459;
}

progress.success {
  color: #006d1a;
}

progress.success::-webkit-progress-value {
  background: #006d1a;
}

progress.success::-moz-progress-bar {
  background: #006d1a;
}

progress.alert {
  color: #f74545;
}

progress.alert::-webkit-progress-value {
  background: #f74545;
}

progress.alert::-moz-progress-bar {
  background: #f74545;
}

progress.warning {
  color: #dbab10;
}

progress.warning::-webkit-progress-value {
  background: #dbab10;
}

progress.warning::-moz-progress-bar {
  background: #dbab10;
}

progress.menu {
  color: #000;
}

progress.menu::-webkit-progress-value {
  background: #000;
}

progress.menu::-moz-progress-bar {
  background: #000;
}

progress::-ms-fill {
  border: 0;
  border-radius: 0;
}

body.is-reveal-open {
  overflow: hidden;
}

html.is-reveal-open, html.is-reveal-open body {
  -webkit-user-select: none;
  user-select: none;
  min-height: 100%;
  position: fixed;
  overflow: hidden;
}

.reveal-overlay {
  z-index: 1005;
  background-color: #33333373;
  display: none;
  position: fixed;
  inset: 0;
  overflow-y: scroll;
}

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  background-color: #fff;
  border: 1px solid #cfcfcf;
  border-radius: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  display: none;
  position: relative;
  top: 100px;
  overflow-y: auto;
}

[data-whatinput="mouse"] .reveal {
  outline: 0;
}

@media print, screen and (width >= 40em) {
  .reveal {
    min-height: 0;
  }
}

.reveal .column, .reveal .columns {
  min-width: 0;
}

.reveal > :last-child {
  margin-bottom: 0;
}

@media print, screen and (width >= 40em) {
  .reveal {
    width: 600px;
    max-width: 1000px;
  }
}

.reveal.collapse {
  padding: 0;
}

@media print, screen and (width >= 40em) {
  .reveal.tiny {
    width: 30%;
    max-width: 1000px;
  }

  .reveal.small {
    width: 50%;
    max-width: 1000px;
  }

  .reveal.large {
    width: 90%;
    max-width: 1000px;
  }
}

.reveal.full {
  border: 0;
  border-radius: 0;
  width: 100%;
  max-width: none;
  height: 100vh;
  min-height: 100vh;
  margin-left: 0;
  top: 0;
  left: 0;
}

@media screen and (width <= 39.9375em) {
  .reveal {
    border: 0;
    border-radius: 0;
    width: 100%;
    max-width: none;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    top: 0;
    left: 0;
  }
}

.reveal.without-overlay {
  position: fixed;
}

table {
  border-collapse: collapse;
  border-radius: 0;
  width: 100%;
  margin-bottom: 1rem;
}

thead, tbody, tfoot {
  background-color: #fff;
  border: 0;
}

caption {
  padding: .571429rem .714286rem .714286rem;
  font-weight: 700;
}

thead {
  color: #333;
  background: #fff;
}

tfoot {
  color: #333;
  background: #f2f2f2;
}

thead tr, tfoot tr {
  background: none;
}

thead th, thead td, tfoot th, tfoot td {
  text-align: left;
  padding: .571429rem .714286rem .714286rem;
  font-weight: 700;
}

tbody th, tbody td {
  padding: .571429rem .714286rem .714286rem;
}

tbody tr:nth-child(2n) {
  background-color: #e8e8e8;
  border-bottom: 0;
}

table.unstriped tbody {
  background-color: #fff;
}

table.unstriped tbody tr {
  background-color: #fff;
  border-bottom: 0;
}

@media screen and (width <= 63.9375em) {
  table.stack thead, table.stack tfoot {
    display: none;
  }

  table.stack tr, table.stack th, table.stack td {
    display: block;
  }

  table.stack td {
    border-top: 0;
  }
}

table.scroll {
  width: 100%;
  display: block;
  overflow-x: auto;
}

table.hover thead tr:hover {
  background-color: #fafafa;
}

table.hover tfoot tr:hover {
  background-color: #ededed;
}

table.hover tbody tr:hover {
  background-color: #fafafa;
}

table.hover:not(.unstriped) tr:nth-of-type(2n):hover {
  background-color: #ededed;
}

.table-scroll {
  overflow-x: auto;
}

.table-scroll table {
  width: auto;
}

.top-bar {
  padding: .5rem;
}

.top-bar:before, .top-bar:after {
  content: " ";
  display: table;
}

.top-bar:after {
  clear: both;
}

.top-bar, .top-bar ul {
  background-color: #fff;
}

.top-bar input {
  max-width: 200px;
  margin-right: 1rem;
}

.top-bar .input-group-field {
  width: 100%;
  margin-right: 0;
}

.top-bar input.button {
  width: auto;
}

.top-bar .top-bar-left, .top-bar .top-bar-right {
  width: 100%;
}

@media print, screen and (width >= 40em) {
  .top-bar .top-bar-left, .top-bar .top-bar-right {
    width: auto;
  }
}

@media screen and (width <= 63.9375em) {
  .top-bar.stacked-for-medium .top-bar-left, .top-bar.stacked-for-medium .top-bar-right {
    width: 100%;
  }
}

@media screen and (width <= 74.9375em) {
  .top-bar.stacked-for-large .top-bar-left, .top-bar.stacked-for-large .top-bar-right {
    width: 100%;
  }
}

.top-bar-title {
  float: left;
  padding: .5rem 1rem .5rem 0;
  display: inline-block;
}

.top-bar-title .menu-icon {
  bottom: 2px;
}

.top-bar-left {
  float: left;
}

.top-bar-right {
  float: right;
}

.hide {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

@media screen and (width <= 39.9375em) {
  .hide-for-small-only {
    display: none !important;
  }
}

@media screen and (width <= 0), screen and (width >= 40em) {
  .show-for-small-only {
    display: none !important;
  }
}

@media print, screen and (width >= 40em) {
  .hide-for-medium {
    display: none !important;
  }
}

@media screen and (width <= 39.9375em) {
  .show-for-medium {
    display: none !important;
  }
}

@media screen and (width >= 40em) and (width <= 63.9375em) {
  .hide-for-medium-only {
    display: none !important;
  }
}

@media screen and (width <= 39.9375em), screen and (width >= 64em) {
  .show-for-medium-only {
    display: none !important;
  }
}

@media print, screen and (width >= 64em) {
  .hide-for-large {
    display: none !important;
  }
}

@media screen and (width <= 63.9375em) {
  .show-for-large {
    display: none !important;
  }
}

@media screen and (width >= 64em) and (width <= 74.9375em) {
  .hide-for-large-only {
    display: none !important;
  }
}

@media screen and (width <= 63.9375em), screen and (width >= 75em) {
  .show-for-large-only {
    display: none !important;
  }
}

.show-for-sr, .show-on-focus {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  position: absolute !important;
}

.show-on-focus:active, .show-on-focus:focus {
  clip: auto;
  white-space: normal;
  clip-path: none;
  width: auto;
  height: auto;
  overflow: visible;
  position: static !important;
}

.show-for-landscape, .hide-for-portrait {
  display: block !important;
}

@media screen and (orientation: landscape) {
  .show-for-landscape, .hide-for-portrait {
    display: block !important;
  }
}

@media screen and (orientation: portrait) {
  .show-for-landscape, .hide-for-portrait {
    display: none !important;
  }
}

.hide-for-landscape, .show-for-portrait {
  display: none !important;
}

@media screen and (orientation: landscape) {
  .hide-for-landscape, .show-for-portrait {
    display: none !important;
  }
}

@media screen and (orientation: portrait) {
  .hide-for-landscape, .show-for-portrait {
    display: block !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.menu-overlay {
  display: none;
}

@media screen and (width <= 63.9375em) {
  .breadcrumbs {
    display: none;
  }

  .menu-toggle-buttons img, img.brand {
    height: 45px;
  }

  .menu-toggle-buttons {
    text-align: right;
  }

  .menu-container {
    z-index: 100;
    visibility: hidden;
    background-color: #fff;
    width: 275px;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    overflow: scroll;
  }

  .menu-container li {
    display: block;
  }

  .secondary-menu {
    display: none;
  }

  body {
    transition: margin .25s;
  }

  body.mobile-nav-open {
    margin-left: -275px;
    margin-right: 275px;
  }

  body.mobile-nav-open .menu-container {
    visibility: visible;
  }

  body.mobile-nav-open .menu-overlay {
    z-index: 100;
    background-color: #33333380;
    width: 100%;
    height: 150%;
    display: block;
    position: fixed;
    top: 0;
    left: -275px;
  }

  li.is-drilldown-submenu-parent a:after {
    content: ">";
    float: right;
    color: #777;
    padding-right: .5rem;
  }

  li.js-drilldown-back a:after {
    content: "";
  }

  li.js-drilldown-back a:before {
    content: "< ";
    font-weight: 300;
  }

  header.top-bar.header ul.main-menu {
    width: 275px;
  }

  header.top-bar.header ul.main-menu .submenu.is-active {
    min-height: 100%;
  }

  header.top-bar.header ul.main-menu li + li {
    border-top: 1px solid #e8e8e8;
  }

  header.top-bar.header ul.main-menu li.secondary-menu-item {
    border-top: none;
  }

  header.top-bar.header ul.main-menu li.js-drilldown-back {
    border-bottom: 2px solid #870707;
    min-height: 69px;
    padding-top: 15px;
    font-weight: 300;
  }
}

@media print, screen and (width >= 64em) {
  .menu-toggle-buttons {
    display: none;
  }

  img.brand {
    height: 82px;
  }
}

@media screen and (width <= 39.9375em) {
  .no-js .top-bar {
    display: none;
  }
}

@media print, screen and (width >= 40em) {
  .no-js .title-bar {
    display: none;
  }
}

header.top-bar.header {
  border-top: 7px solid #0b1459;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 30px;
  font-family: Fjalla One, sans-serif;
  box-shadow: 0 6px 6px #0000002b;
}

header.top-bar.header a {
  color: #000;
}

@media print, screen and (width >= 64em) {
  header.top-bar.header ul.main-menu, header.top-bar.header ul.secondary-menu {
    clear: right;
    float: right;
    width: auto;
  }

  header.top-bar.header ul.main-menu .unloaded, header.top-bar.header ul.secondary-menu .unloaded {
    display: none;
  }

  header.top-bar.header ul.is-dropdown-submenu {
    min-width: 250px;
    font-family: Raleway, Helvetica, Arial, sans-serif;
    font-size: 16px;
  }

  header.top-bar.header ul.first-sub ul {
    border: 1px solid #e8e8e8;
  }

  header.top-bar.header ul.main-menu > li.is-active li.is-dropdown-submenu-parent:after {
    content: ">";
    color: #777;
    padding-right: .5rem;
    position: absolute;
    top: 0;
    right: 0;
  }

  header.top-bar.header ul.main-menu > li.is-active li.is-active {
    background-color: #e8e8e8;
  }

  header.top-bar.header li {
    display: inline-block;
  }

  header.top-bar.header li.secondary-menu-item {
    display: none;
  }

  header.top-bar.header li.is-submenu-item:hover {
    background-color: #e8e8e8;
  }

  header.top-bar.header li.is-submenu-item a {
    width: 100%;
  }

  header.top-bar.header li a {
    margin-left: 5px;
    padding: 4px 2px 0;
    display: inline-block;
  }

  header.top-bar.header li a.search {
    background-image: url("searchicon.c29ecb6d.png");
    background-position: -3px -1px;
    background-repeat: no-repeat;
    background-size: auto 24px;
    height: 28px;
    padding-left: 23px;
  }
}

#menuBackground {
  background-color: #fff;
  width: 100%;
  position: absolute;
  top: 130px;
  box-shadow: 0 6px 6px #0000002b;
}

.secondary-menu, .secondary-menu-item {
  font-family: Raleway, Helvetica, Arial, sans-serif;
  font-weight: 300;
}

.secondary-menu a, .secondary-menu-item a {
  font-weight: 300;
}

@media print, screen and (width >= 64em) {
  .secondary-menu li + li:before, .secondary-menu-item li + li:before {
    content: "|";
  }

  .secondary-menu li a, .secondary-menu-item li a {
    margin-right: 5px;
  }
}

.main-menu {
  padding-top: 36px;
  font-size: 16px;
}

@media print, screen and (width >= 64em) {
  .main-menu {
    z-index: 20;
    font-size: 18px;
  }

  .main-menu > li + li:before {
    content: "|";
  }

  .main-menu > li.secondary-menu-item + li:before {
    content: none;
  }

  .main-menu ul li {
    font-weight: 500;
  }
}

.breadcrumbs {
  font-weight: 700;
}

.breadcrumbs a {
  font-weight: normal;
  text-decoration: none;
}

.breadcrumbs li.ancestor {
  font-weight: normal;
}

.breadcrumbs li.current {
  font-weight: 600;
}

.breadcrumbs li:not(:last-child):after {
  color: #333;
  font-weight: normal;
}

.module {
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 43px 20px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 6px 6px #0000002b;
}

.module.payment-instrument {
  padding: 1.5rem;
}

.module.payment-instrument .card-icon {
  float: right;
  width: 4rem;
}

.module.payment-instrument .button {
  margin-bottom: 0;
}

.module a.entirety {
  color: #fff;
  font-weight: bold;
  text-decoration: underline;
}

.module a.entirety:after {
  content: " >>";
}

.module[style*="background-image"] {
  color: #fff;
  background-position: center;
  background-size: cover;
  padding: 0;
}

.module[style*="background-image"] h1, .module[style*="background-image"] h2, .module[style*="background-image"] h3, .module[style*="background-image"] h1 a, .module[style*="background-image"] h2 a, .module[style*="background-image"] h3 a {
  color: #fff;
}

.module[style*="background-image"] p a {
  color: inherit;
  font-weight: bold;
}

.module[style*="background-image"] p a.button {
  font-weight: 400;
}

.module[style*="background-image"] .overlay {
  background-color: #33333380;
  height: 100%;
  padding: 43px 20px;
}

.module .button-pinned {
  height: 43px;
}

.module .button-pinned .button {
  margin: 0;
  position: absolute;
  bottom: 43px;
}

.button {
  padding: 12px;
  font-family: Fjalla One, sans-serif;
  font-size: 18px;
  text-decoration: none;
}

.button.disabled, .button[disabled] {
  opacity: inherit;
  background-color: #777;
}

.button.disabled.busy, .button[disabled].busy {
  cursor: wait;
}

.button.disabled:hover, .button[disabled]:hover {
  background-color: #777;
}

.button.small-full-width {
  line-height: 24px;
}

@media screen and (width <= 39.9375em) {
  .button.small-full-width {
    width: 100%;
  }
}

@media print, screen and (width >= 40em) {
  .button.small-full-width {
    width: 50%;
  }
}

@media screen and (width >= 64em) {
  .button.small-full-width {
    width: 25%;
  }
}

.windows .button {
  padding: 14px 12px 10px;
}

@media screen and (width <= 39.9375em) {
  .button-group {
    display: none;
  }
}

.button-group.expanded {
  flex-flow: wrap;
  justify-content: flex-start;
  display: flex;
}

.button-group.expanded .button {
  flex: 1 1 content;
}

.button-group.expanded .button.label {
  flex: 0 0 10%;
}

.button-group .button {
  color: #870707;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: #cfcfcf;
  font-size: 14px;
  overflow: hidden;
}

.button-group .button.active {
  color: #000;
  background-color: #fff;
  border: 1px solid #cfcfcf;
}

.button-group .button.disabled {
  color: #777;
  background-color: #e8e8e8;
}

.button-group .button.label {
  color: #333;
  cursor: unset;
  background-color: #fff;
  display: inline-block;
}

table thead {
  border-bottom: 1px solid #e8e8e8;
}

table thead th {
  white-space: nowrap;
}

table tbody th, table tbody td {
  vertical-align: baseline;
}

table tfoot td {
  font-weight: 400;
}

table caption {
  font-style: italic;
  font-weight: 400;
}

table th {
  text-align: left;
}

table th .secondary, table th h1, table th h2 {
  font-weight: normal;
}

table th.center, table td.center {
  text-align: center;
}

table th label, table td label {
  font: inherit;
  background: inherit;
  color: inherit;
}

table th.numeric, table td.numeric {
  text-align: right;
  font-variant-numeric: lining-nums tabular-nums;
  -webkit-font-feature-settings: "lnum";
  -moz-font-feature-settings: "lnum";
  font-feature-settings: "lnum";
}

table.no-left-padding th:first-child, table.no-left-padding td:first-child {
  padding-left: 0;
}

table.no-datatables {
  display: block;
  overflow-x: auto;
}

table.unstriped tr.striped {
  background-color: #e8e8e8;
}

.dataTables_wrapper {
  margin-bottom: 1rem;
}

.dt-floating-wrapper {
  position: fixed;
  overflow: hidden;
}

.dt-floating-wrapper table.dataTable {
  position: relative;
  margin: 0 !important;
}

.dataTables_empty {
  display: none;
}

.dataTable .sorting {
  background-size: 25px;
  background-image: url("table-sort-unsorted.65aad172.png") !important;
}

.dataTable .sorting_asc {
  background-size: 25px;
  background-image: url("table-sort-asc.a4a6bd8b.png") !important;
}

.dataTable .sorting_desc {
  background-size: 25px;
  background-image: url("table-sort-desc.408f9783.png") !important;
}

section#player-info + section#tournament-info {
  margin-top: 0;
}

form.inline {
  display: inline;
}

select:not(.select2) {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28119, 119, 119%29'></polygon></svg>");
  background-position: right -1.14286rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  background-origin: content-box;
  padding-right: 1.71429rem;
}

select:focus {
  background-color: #fff;
  border: 1px solid #006d1a;
  outline: none;
  transition: box-shadow .5s, border-color .25s ease-in-out;
  box-shadow: 0 0 5px #006d1a;
}

input.has-been-focused:invalid, input.has-been-focused:focus:invalid, select.has-been-focused:invalid, select.has-been-focused:focus:invalid, input.incorrect, select.incorrect {
  border: 1px solid #f74545;
  box-shadow: 0 0 5px #f74545;
}

select.inline {
  width: inherit;
}

.help-text em {
  font-weight: 600;
}

.help-text:first-child {
  margin-top: inherit;
}

.help-text.middle {
  margin: 0 0 1.14286rem;
  padding: .642857rem 0;
}

.row .stack-below-checkbox, .indented-by-checkbox-width {
  padding-left: 46px;
}

.custom-checkbox, .custom-radio {
  display: inline-block;
}

.custom-checkbox .custom-indicator, .custom-radio .custom-indicator {
  cursor: pointer;
  background-position: -9px -9px;
  background-size: 50px 50px;
  width: 32px;
  height: 32px;
  margin-left: -41px;
  display: inline-block;
  position: relative;
  top: 11px;
}

.custom-checkbox input, .custom-radio input {
  opacity: 0;
  width: 50px;
}

.custom-checkbox label, .custom-radio label {
  display: inline-block;
  position: relative;
  left: 6px;
}

.custom-checkbox input:enabled ~ .custom-indicator {
  background-image: url("checkbox-enabled-unchecked.c8dab785.png");
}

.custom-checkbox input:enabled:focus ~ .custom-indicator {
  background-image: url("checkbox-enabled-unchecked-focused.636dff22.png");
}

.custom-checkbox input:enabled:checked ~ .custom-indicator {
  background-image: url("checkbox-enabled-checked.960acba1.png");
}

.custom-checkbox input:enabled:checked:focus ~ .custom-indicator {
  background-image: url("checkbox-enabled-checked-focused.965caf02.png");
}

.custom-checkbox input:disabled ~ .custom-indicator {
  cursor: not-allowed;
  background-image: url("checkbox-disabled-unchecked.9223d740.png");
}

.custom-checkbox input:disabled:checked ~ .custom-indicator {
  cursor: not-allowed;
  background-image: url("checkbox-disabled-checked.89e2af21.png");
}

.custom-radio input:enabled ~ .custom-indicator {
  background-image: url("radio-enabled-unchecked.085e9766.png");
}

.custom-radio input:enabled:focus ~ .custom-indicator {
  background-image: url("radio-enabled-unchecked-focused.081ca150.png");
}

.custom-radio input:enabled:checked ~ .custom-indicator {
  background-image: url("radio-enabled-checked.53d0a2bd.png");
}

.custom-radio input:enabled:checked:focus ~ .custom-indicator {
  background-image: url("radio-enabled-checked-focused.94644d98.png");
}

.custom-radio input:disabled ~ .custom-indicator {
  cursor: not-allowed;
  background-image: url("radio-disabled-unchecked.35015678.png");
}

.custom-radio input:disabled:checked ~ .custom-indicator {
  cursor: not-allowed;
  background-image: url("radio-disabled-checked.c571fbe2.png");
}

.label {
  font-family: Fjalla One, sans-serif;
  font-weight: 500;
}

@media screen and (width <= 39.9375em) {
  label.middle, .label.middle {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

label.required:after, .label.required:after {
  color: #777;
  content: " (required)";
  font-weight: 300;
}

label.optional:after, .label.optional:after {
  color: #777;
  content: " (optional)";
  font-weight: 300;
}

th > input[type="text"], th input[type="text"], th input[type="password"], th input[type="date"], th input[type="datetime"], th input[type="datetime-local"], th input[type="month"], th input[type="week"], th input[type="email"], th input[type="number"], th input[type="search"], th input[type="tel"], th input[type="time"], th input[type="url"], th .sq-input, td > input[type="text"], td input[type="text"], td input[type="password"], td input[type="date"], td input[type="datetime"], td input[type="datetime-local"], td input[type="month"], td input[type="week"], td input[type="email"], td input[type="number"], td input[type="search"], td input[type="tel"], td input[type="time"], td input[type="url"], td .sq-input {
  width: inherit;
  height: inherit;
  display: inline-block !important;
}

th > input[type="text"]:only-child, th input[type="text"]:only-child, th input[type="password"]:only-child, th input[type="date"]:only-child, th input[type="datetime"]:only-child, th input[type="datetime-local"]:only-child, th input[type="month"]:only-child, th input[type="week"]:only-child, th input[type="email"]:only-child, th input[type="number"]:only-child, th input[type="search"]:only-child, th input[type="tel"]:only-child, th input[type="time"]:only-child, th input[type="url"]:only-child, th .sq-input:only-child, td > input[type="text"]:only-child, td input[type="text"]:only-child, td input[type="password"]:only-child, td input[type="date"]:only-child, td input[type="datetime"]:only-child, td input[type="datetime-local"]:only-child, td input[type="month"]:only-child, td input[type="week"]:only-child, td input[type="email"]:only-child, td input[type="number"]:only-child, td input[type="search"]:only-child, td input[type="tel"]:only-child, td input[type="time"]:only-child, td input[type="url"]:only-child, td .sq-input:only-child {
  margin-bottom: 0;
}

th > input[type="text"].full-width, th input[type="text"].full-width, th input[type="password"].full-width, th input[type="date"].full-width, th input[type="datetime"].full-width, th input[type="datetime-local"].full-width, th input[type="month"].full-width, th input[type="week"].full-width, th input[type="email"].full-width, th input[type="number"].full-width, th input[type="search"].full-width, th input[type="tel"].full-width, th input[type="time"].full-width, th input[type="url"].full-width, th .sq-input.full-width, td > input[type="text"].full-width, td input[type="text"].full-width, td input[type="password"].full-width, td input[type="date"].full-width, td input[type="datetime"].full-width, td input[type="datetime-local"].full-width, td input[type="month"].full-width, td input[type="week"].full-width, td input[type="email"].full-width, td input[type="number"].full-width, td input[type="search"].full-width, td input[type="tel"].full-width, td input[type="time"].full-width, td input[type="url"].full-width, td .sq-input.full-width {
  width: 100%;
}

th > select, td > select {
  width: inherit;
  height: inherit;
  display: inline-block !important;
}

th > select:only-child, td > select:only-child {
  margin-bottom: 0;
}

th > select.full-width, td > select.full-width {
  width: 100%;
}

th > textarea, td > textarea {
  width: inherit;
  height: inherit;
  display: inline-block !important;
}

th > textarea:only-child, td > textarea:only-child {
  margin-bottom: 0;
}

th > textarea.full-width, td > textarea.full-width {
  width: 100%;
}

.select2 {
  appearance: none;
  color: #333;
  background-color: #fff;
  background-image: none;
  background-position: right -1.14286rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  background-origin: content-box;
  border: 1px solid #cfcfcf;
  border-radius: 0;
  height: 2.78571rem;
  margin: 0 0 1.14286rem;
  padding: .571429rem 1.71429rem .571429rem .571429rem;
  font-family: inherit;
  font-size: 1.14286rem;
  font-weight: 400;
  line-height: 1.5;
  transition: box-shadow .5s, border-color .25s ease-in-out;
}

.select2:focus {
  background-color: #fff;
  border: 1px solid #006d1a;
  outline: none;
  transition: box-shadow .5s, border-color .25s ease-in-out;
  box-shadow: 0 0 5px #006d1a;
}

.select2:disabled {
  cursor: not-allowed;
  background-color: #e8e8e8;
}

.select2::-ms-expand {
  display: none;
}

.select2[multiple] {
  background-image: none;
  height: auto;
}

.select2-results li, .select2-search input {
  margin-bottom: inherit;
}

.select2-container--default .select2-selection--single {
  border: none;
}

.select2-container--default {
  margin-bottom: 0;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: normal;
}

.xdsoft_datetimepicker {
  font-family: Raleway, Helvetica, Arial, sans-serif;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option:hover {
  background-color: #0b1459;
}

.xdsoft_datetimepicker .xdsoft_calendar td:hover, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:hover {
  background-color: #0b1459 !important;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_today {
  color: #870707;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option.xdsoft_current {
  background-color: #777;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default, .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current {
  background: #777;
  box-shadow: inset 0 1px 3px #333;
}

select[multiple] {
  padding: 0;
}

.inline-fields input[type="text"] {
  width: inherit;
  width: inherit;
  display: inline-block;
}

.inline-fields input[type="password"], .inline-fields input[type="date"], .inline-fields input[type="datetime"], .inline-fields input[type="datetime-local"], .inline-fields input[type="month"], .inline-fields input[type="week"], .inline-fields input[type="email"], .inline-fields input[type="number"], .inline-fields input[type="search"], .inline-fields input[type="tel"], .inline-fields input[type="time"], .inline-fields input[type="url"], .inline-fields .sq-input, .inline-fields select {
  width: inherit;
  display: inline-block;
}

.inline-fields .inline {
  width: unset;
}

.inline-fields .inline.date-pair {
  width: 47%;
}

.inline-fields p {
  white-space: nowrap;
}

.inline-fields p label {
  display: inline;
}

.inline-fields label.inline {
  margin: 1rem;
  display: inline-block;
}

.inline-fields label.inline:first-child {
  margin-left: 0;
}

.field-group {
  background-color: #cfcfcf;
  margin-bottom: 1rem;
}

.field-group.transparent {
  background-color: inherit;
}

.field-group.tight {
  margin-bottom: 0;
}

.field-group fieldset:not(.unpadded) {
  padding: 1rem;
}

.field-group fieldset.only-vertical-padding {
  padding: 1rem 0;
}

.field-group fieldset.initial-checkbox {
  padding-top: 0;
}

.field-group fieldset.tight {
  padding: 0;
}

.field-group fieldset.right-padding {
  padding-right: 1rem;
}

.filter-box-button {
  display: none;
}

@media screen and (width <= 39.9375em) {
  .filter-box-button {
    color: #333;
    text-transform: uppercase;
    background-color: #e8e8e8;
    margin-bottom: 1rem;
    padding: 1rem;
    font-weight: 700;
    display: block;
  }

  .filter-box-button:after {
    content: ">";
    float: right;
  }

  .filter-box-button:hover {
    color: inherit;
  }

  .filter-box-button.is-active {
    color: inherit;
    margin-bottom: 0;
  }

  .filter-box-button.is-active:after {
    content: "V";
  }

  .filter-box-button + .filter-box {
    display: none;
  }

  .filter-box-button.is-active + .filter-box {
    display: block;
  }
}

.filter-box {
  background-color: #e8e8e8;
  margin-bottom: 1rem;
  padding: 1rem;
}

.filter-box li {
  vertical-align: top;
  padding-right: 1rem;
}

.filter-box li > label, .filter-box li legend {
  font-weight: 600;
}

.filter-box li > label input, .filter-box li > label select, .filter-box li > label button, .filter-box li > label .select2-selection, .filter-box li legend input, .filter-box li legend select, .filter-box li legend button, .filter-box li legend .select2-selection {
  font-family: Raleway, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: normal;
}

.filter-box li > .button {
  margin-top: 1.5rem;
  margin-bottom: .5rem;
}

@media screen and (width >= 40em) {
  .filter-box li > .button.no-top-margin {
    margin-top: 0;
  }
}

.gsc-control-cse * {
  box-sizing: content-box !important;
}

.gsc-control-cse input {
  box-shadow: none !important;
}

.gsc-control-cse input :focus {
  outline: none;
}

.gsc-control-cse table {
  background: none;
  border: none;
}

.gsc-control-cse table td.gsib_b {
  padding: 0;
}

table.gsc-completion-container tbody tr {
  background: none;
}

.gsc-input-box, .gsc-search-box .gsc-input > input, .gsc-input-box-hover {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.error, .errors {
  color: #f74545;
  font-weight: bold;
}

.error.label, .error h1, .error h2, .error h3, .error h4, .error h5, .error h6, h1 .error, h2 .error, h3 .error, h4 .error, h5 .error, h6 .error, .errors.label, .errors h1, .errors h2, .errors h3, .errors h4, .errors h5, .errors h6, h1 .errors, h2 .errors, h3 .errors, h4 .errors, h5 .errors, h6 .errors {
  font-weight: normal;
}

.error em, .errors em {
  font-weight: inherit;
}

.error a, .errors a {
  color: #cd0909 !important;
  font-weight: inherit !important;
  text-decoration: underline dotted !important;
}

.error-background {
  color: #f74545;
}

.important-instruction:before, .important-instructions:before {
  content: "→";
  font-weight: bold;
}

.warning, .warnings, .important-instruction, .important-instructions {
  color: #b1590c;
  font-weight: bold;
}

.warning.label, .warning h1, .warning h2, .warning h3, .warning h4, .warning h5, .warning h6, h1 .warning, h2 .warning, h3 .warning, h4 .warning, h5 .warning, h6 .warning, .warnings.label, .warnings h1, .warnings h2, .warnings h3, .warnings h4, .warnings h5, .warnings h6, h1 .warnings, h2 .warnings, h3 .warnings, h4 .warnings, h5 .warnings, h6 .warnings, .important-instruction.label, .important-instruction h1, .important-instruction h2, .important-instruction h3, .important-instruction h4, .important-instruction h5, .important-instruction h6, h1 .important-instruction, h2 .important-instruction, h3 .important-instruction, h4 .important-instruction, h5 .important-instruction, h6 .important-instruction, .important-instructions.label, .important-instructions h1, .important-instructions h2, .important-instructions h3, .important-instructions h4, .important-instructions h5, .important-instructions h6, h1 .important-instructions, h2 .important-instructions, h3 .important-instructions, h4 .important-instructions, h5 .important-instructions, h6 .important-instructions {
  font-weight: normal;
}

.warning em, .warnings em, .important-instruction em, .important-instructions em {
  font-weight: inherit;
}

.warning a, .warnings a, .important-instruction a, .important-instructions a {
  color: #512906 !important;
  font-weight: inherit !important;
  text-decoration: underline dotted !important;
}

.warning-background {
  background-color: #b1590c;
}

.caution {
  color: #dbab10;
  font-weight: bold;
}

.caution.label, .caution h1, .caution h2, .caution h3, .caution h4, .caution h5, .caution h6, h1 .caution, h2 .caution, h3 .caution, h4 .caution, h5 .caution, h6 .caution {
  font-weight: normal;
}

.caution em {
  font-weight: inherit;
}

.caution a {
  color: #7c6109 !important;
  font-weight: inherit !important;
  text-decoration: underline dotted !important;
}

.error-background {
  background-color: #dbab10;
}

.success {
  color: #006d1a;
  font-weight: bold;
}

.success.label, .success h1, .success h2, .success h3, .success h4, .success h5, .success h6, h1 .success, h2 .success, h3 .success, h4 .success, h5 .success, h6 .success {
  font-weight: normal;
}

.success em {
  font-weight: inherit;
}

.success a {
  color: #00a026 !important;
  font-weight: inherit !important;
  text-decoration: underline dotted !important;
}

.success-background {
  background-color: #006d1a;
}

p.help-text {
  margin-bottom: 0;
}

p.help-text.before-field {
  margin-top: 1rem;
}

.of-subform.deleting {
  opacity: .3;
  background-color: #fff;
}

.moderator-evaluation .slider-container {
  margin-bottom: 2rem;
}

.moderator-evaluation .slider-container input[type="range"][data-interacted="false"]::-webkit-slider-thumb {
  box-shadow: none;
  background-color: #0000;
  border-color: #0000;
}

.moderator-evaluation .slider-container input[type="range"][data-interacted="false"]::-moz-range-thumb {
  box-shadow: none;
  background-color: #0000;
  border-color: #0000;
}

.moderator-evaluation .slider-container input[type="range"][data-interacted="false"]::-ms-thumb {
  box-shadow: none;
  background-color: #0000;
  border-color: #0000;
}

.moderator-evaluation .slider-container .slider-value {
  text-align: right;
}

@media screen and (width >= 40em) {
  .moderator-evaluation .slider-container .slider-value {
    font-size: .85rem;
  }
}

.moderator-evaluation .slider-container .slider-header-labels {
  flex-direction: row;
  display: flex;
}

.moderator-evaluation .slider-container .slider-header-labels div {
  text-align: center;
  flex: 1;
}

.moderator-evaluation .slider-container .slider-header-labels div:first-child {
  text-align: left;
}

.moderator-evaluation .slider-container .slider-header-labels div:last-child {
  text-align: right;
}

.moderator-evaluation .speed-explanation {
  padding-left: 4rem;
}

@media print {
  @page {
    margin: .25in .5in .5in;
  }

  header.top-bar.header {
    border-top: 0 !important;
  }

  header .menu-toggle-buttons, header .menu-container {
    display: none;
  }

  img.brand {
    height: 1in;
  }

  .button-menu, .module:not(.printing) {
    display: none;
  }

  a[href]:after {
    content: none !important;
  }

  blockquote {
    border: none;
  }

  footer .social, footer .links {
    display: none;
  }

  h1, h2, h3, h4, h5, h6 {
    break-after: avoid;
    page-break-after: avoid;
  }

  h1 + p, h1 + table, h2 + p, h2 + table, h3 + p, h3 + table, h4 + p, h4 + table, h5 + p, h5 + table, h6 + p, h6 + table {
    break-before: avoid;
    page-break-before: avoid;
  }

  .break-before {
    break-before: page;
    page-break-before: always;
  }

  .avoid-break-inside, avoid-break-inside * {
    break-inside: avoid;
    page-break-inside: avoid;
    -webkit-column-break-inside: avoid;
  }

  .non-printing {
    display: none;
  }
}

.instagram-media {
  background: #fff;
  border: 0;
  border-radius: 3px;
  width: 100%;
  margin: 1px;
  padding: 0;
  box-shadow: 0 0 1px #00000080, 0 1px 10px #00000026;
}

.instagram-media .instagram-inner-1 {
  padding: 8px;
}

.instagram-media .instagram-inner-1 .instagram-inner-2 {
  text-align: center;
  background: #f8f8f8;
  width: 100%;
  margin-top: 40px;
  padding: 37.5% 0;
  line-height: 0;
}

.instagram-media .instagram-inner-1 .instagram-inner-2 .instagram-inner-3 {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAMAAAApWqozAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAMUExURczMzPf399fX1+bm5mzY9AMAAADiSURBVDjLvZXbEsMgCES5/P8/t9FuRVCRmU73JWlzosgSIIZURCjo/ad+EQJJB4Hv8BFt+IDpQoCx1wjOSBFhh2XssxEIYn3ulI/6MNReE07UIWJEv8UEOWDS88LY97kqyTliJKKtuYBbruAyVh5wOHiXmpi5we58Ek028czwyuQdLKPG1Bkb4NnM+VeAnfHqn1k4+GPT6uGQcvu2h2OVuIf/gWUFyy8OWEpdyZSa3aVCqpVoVvzZZ2VTnn2wU8qzVjDDetO90GSy9mVLqtgYSy231MxrY6I2gGqjrTY0L8fxCxfCBbhWrsYYAAAAAElFTkSuQmCC");
  width: 44px;
  height: 44px;
  margin: 0 auto -44px;
  display: block;
  position: relative;
  top: -22px;
}

.instagram-media .instagram-caption {
  margin: 8px 0 0;
  padding: 0 4px;
}

.instagram-media .instagram-caption a {
  color: #000;
  word-wrap: break-word;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 17px;
  text-decoration: none;
}

.instagram-media .instagram-attribution {
  color: #c9c8cd;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 8px;
  margin-bottom: 0;
  padding: 8px 0 7px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 17px;
  overflow: hidden;
}

.instagram-media .instagram-attribution time {
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 17px;
}

.embedded-video {
  width: 560px;
  height: 315px;
}

.social-media-icon-inline {
  width: 32px;
  height: 32px;
}

.social-media-icon-inline:first-child {
  margin-right: .5rem;
}

.buzzword-wordmark-container .buzzword-underwriter-logo {
  float: right;
}

.buzzword-wordmark {
  width: 95%;
  max-width: 400px;
  margin-bottom: 1rem;
}

@media print {
  .buzzword-wordmark {
    width: 300px;
  }
}

.buzzword-logo-container {
  float: right;
}

@media screen and (width <= 39.9375em) {
  .buzzword-logo {
    display: none;
  }
}

@media screen and (width >= 40em) {
  .buzzword-logo {
    width: 150px;
    height: 150px;
    margin-bottom: 1rem;
  }
}

@media print {
  .buzzword-logo {
    width: 150px;
  }
}

.buzzword-underwriter-logo {
  width: 150px;
  margin-bottom: 1rem;
}

@media screen and (width <= 39.9375em) {
  .buzzword-special-event-logo {
    display: none;
  }
}

@media screen and (width >= 40em) {
  .buzzword-special-event-logo {
    float: right;
    width: 250px;
    height: 250px;
    margin-bottom: 1rem;
  }
}

.buzzword-special-event-quote {
  background-color: #e8e8e8;
  padding: 1rem;
}

@media screen and (width >= 40em) {
  .buzzword-special-event-quote {
    clear: both;
    float: right;
    width: 50%;
  }
}

.buzzword-special-event-quote figure {
  float: right;
  max-width: 150px;
}

.buzzword-special-event-quote :last-child {
  margin-bottom: 0;
}

.buzzword-previous-question-result {
  background-color: #e8e8e8;
  margin-bottom: 1rem;
  padding: 1rem;
}

@media screen and (width >= 40em) {
  .buzzword-previous-question-result {
    margin-bottom: 2rem;
  }

  .buzzword-previous-question-result:not(.standalone) {
    border-left: 2px solid #cfcfcf;
    margin-left: 2rem;
  }
}

.buzzword-previous-question-result h3 {
  margin-bottom: .5rem;
}

.buzzword-hint {
  font-size: 150%;
}

.buzzword-question-container {
  margin-right: 2rem;
}

.buzzword-big-button {
  width: 100%;
  height: 100%;
  margin-top: .5rem;
  margin-bottom: 0;
  display: block;
}

@media screen and (width >= 40em) {
  .buzzword-toggle-sound-effects {
    width: 50%;
  }
}

.buzzword-timer {
  text-align: center;
  margin-top: .5rem;
  font-family: Fjalla One, sans-serif;
  font-size: 300%;
}

@media screen and (width >= 40em) {
  .buzzword-answering .buzzword-timer {
    margin-top: 2rem;
  }
}

.buzzword-hidden {
  display: none;
}

@media screen and (width >= 40em) {
  .buzzword-review-question-table th:first-child {
    width: 20%;
  }
}

.buzzword-stats-is-self, .buzzword-stats-counted-performance {
  background-color: #a4adf2 !important;
}

.buzzword-menus, .buzzword-stats-menus {
  clear: both;
}

.buzzword-menus nav, .buzzword-stats-menus nav {
  margin-top: .5rem;
}

.buzzword-awards-container {
  min-width: 74px;
}

@media screen and (width >= 40em) {
  .buzzword-awards-container {
    min-width: 98px;
  }
}

.buzzword-awards-inline {
  white-space: nowrap;
  display: inline-block;
}

.buzzword-award-inline {
  width: 1.5rem;
}

a .buzzword-award-inline {
  cursor: pointer;
}

@media screen and (width >= 40em) {
  .buzzword-award-inline {
    width: 2.5rem;
  }
}

.buzzword-award-display-container {
  width: 6rem;
}

.buzzword-award-display-container + td {
  vertical-align: middle;
}

.buzzword-award-display {
  width: 5rem;
}

.buzzword-award-explanation-container {
  margin-bottom: 2rem;
}

.buzzword-award-explanation-container figure {
  width: 10rem;
  margin-left: auto;
  margin-right: auto;
}

.buzzword-award-explanation-container figure .buzzword-award-display {
  width: 100%;
}

@media screen and (width >= 40em) {
  .buzzword-results-filter li:not(:last-child) {
    margin-right: 4rem;
  }

  .buzzword-results-filter li input[type="submit"] {
    margin-top: 0;
  }
}

.buzzword-coach-registration-total-display-outer-container {
  z-index: 1000;
  background-color: #fff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  max-width: 400px;
  padding: 2px 2px 0;
  position: fixed;
  bottom: 0;
  right: 20%;
}

.buzzword-coach-registration-total-display-inner-container {
  border-top: 2px solid #0b1459;
  border-left: 2px solid #0b1459;
  border-right: 2px solid #0b1459;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin: 2px 2px 0;
  padding: 1rem;
}

@media screen and (width >= 40em) {
  .buzzword-coach-registration-total-display-inner-container {
    padding-bottom: 4rem;
  }
}

.coach-registration-price:not(:first-child) {
  margin-left: .3rem;
}

.overridden-price {
  font-weight: normal;
  text-decoration: line-through 2px;
}

.coach-registration-price-unit {
  font-weight: normal;
}

.question {
  font-family: Merriweather, Raleway, Helvetica, Arial, sans-serif;
}

.question .question-body {
  margin-bottom: .5rem;
}

.question .question-body .power {
  font-weight: 700;
}

.question .question-body .power:after {
  content: " (★)";
}

.question .question-intro {
  margin-bottom: .5rem;
}

.question .question-parts {
  list-style-type: upper-alpha;
}

.question .question-answer:not(.suppress-answer-marker):before {
  content: "answer: ";
}

.question .question-answer .required {
  text-underline-position: under;
  text-decoration: underline;
}

.question .pg {
  font-size: 95%;
}

.question .host-note {
  font-style: italic;
}

.question .host-note:before {
  content: "[";
}

.question .host-note:after {
  content: "]";
}

.pg {
  color: #777;
  font-family: Fjalla One, sans-serif;
  font-size: 85%;
  font-weight: 400;
}

.pg:before {
  content: " [";
}

.pg:after {
  content: "]";
}

.priority-protest {
  background-color: coral;
}

.homepage-carousel {
  margin-bottom: 15px;
}

@media screen and (width <= 39.9375em) {
  .homepage-carousel .orbit-slide {
    height: 160px;
  }
}

@media print, screen and (width >= 40em) {
  .homepage-carousel .orbit-slide {
    height: 337px;
  }
}

@media print, screen and (width >= 64em) {
  .homepage-carousel .orbit-slide {
    height: 441px;
  }
}

.homepage-carousel .orbit-slide figure {
  margin: 0;
}

.homepage-carousel .orbit-slide .tournament-promo-logo-container {
  text-align: center;
  height: 100%;
  padding-top: 1rem;
}

@media screen and (width <= 39.9375em) {
  .homepage-carousel .orbit-slide .tournament-promo-logo-container {
    align-items: center;
    display: flex;
  }

  .homepage-carousel .orbit-slide .tournament-promo-logo-container .left-half {
    white-space: nowrap;
    flex: auto;
    overflow: hidden;
  }

  .homepage-carousel .orbit-slide .tournament-promo-logo-container .right-half {
    flex: 0 auto;
  }

  .homepage-carousel .orbit-slide .tournament-promo-logo-container .right-half a.button {
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: .5rem;
    display: block;
    overflow: hidden;
  }
}

@media screen and (width <= 39.9375em) and (width <= 325px) {
  .homepage-carousel .orbit-slide .tournament-promo-logo-container .right-half a.button {
    max-width: 8rem;
  }
}

@media screen and (width <= 39.9375em) {
  .homepage-carousel .orbit-slide .tournament-promo-logo-container .tournament-promo-logo {
    height: 125px;
  }
}

@media print, screen and (width >= 40em) {
  .homepage-carousel .orbit-slide .tournament-promo-logo-container .tournament-promo-logo {
    height: 210px;
  }
}

@media print, screen and (width >= 64em) {
  .homepage-carousel .orbit-slide .tournament-promo-logo-container .tournament-promo-logo {
    height: 310px;
  }
}

.homepage-carousel .orbit-slide .orbit-caption {
  backdrop-filter: blur(10px);
  font-family: Fjalla One, sans-serif;
  bottom: 45px;
}

@media screen and (width <= 39.9375em) {
  .homepage-carousel .orbit-slide .orbit-caption {
    font-size: 20px;
  }
}

@media print, screen and (width >= 40em) {
  .homepage-carousel .orbit-slide .orbit-caption {
    font-size: 28px;
  }
}

@media print, screen and (width >= 64em) {
  .homepage-carousel .orbit-slide .orbit-caption {
    font-size: 36px;
  }
}

.homepage-carousel .orbit-slide .orbit-caption a {
  color: inherit;
}

.homepage-carousel .orbit-slide .orbit-caption h3 {
  margin-bottom: 0;
  font-size: 75%;
}

.homepage-carousel .orbit-slide .orbit-caption p {
  margin-bottom: 0;
  font-family: Raleway, Helvetica, Arial, sans-serif;
  font-size: 40%;
}

@media screen and (width <= 39.9375em) {
  .homepage-carousel .orbit-slide .orbit-caption p {
    display: none;
  }
}

.homepage-carousel .orbit-slide .orbit-caption p a:not(.button) {
  text-decoration: underline;
}

@media screen and (width <= 39.9375em) {
  .homepage-carousel .orbit-slide .orbit-caption {
    bottom: 0;
  }
}

.homepage-carousel .orbit-previous img, .homepage-carousel .orbit-next img {
  width: 36px;
}

@media screen and (width <= 39.9375em) {
  .homepage-carousel .orbit-previous, .homepage-carousel .orbit-next {
    border-radius: 9999px;
    padding: .5rem 1rem;
    position: static;
    transform: none;
  }

  .homepage-carousel .orbit-previous img, .homepage-carousel .orbit-next img {
    width: 15px;
  }

  .homepage-carousel .orbit-navigation {
    background-color: #3336;
    border-radius: 9999px;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    display: flex;
    position: relative;
  }

  .homepage-carousel .orbit-bullets {
    height: 10px;
    margin: 0 0 20px;
    display: inline-block;
  }
}

@media print, screen and (width >= 40em) {
  .homepage-carousel .orbit-bullets {
    width: 100%;
    height: 15px;
    margin: 0;
    position: absolute;
    bottom: 15px;
  }
}

.homepage-carousel .orbit-bullets button {
  background-color: inherit;
  border: 1px solid #e8e8e8;
  width: 15px;
  height: 15px;
}

@media screen and (width <= 39.9375em) {
  .homepage-carousel .orbit-bullets button {
    width: 10px;
    height: 10px;
  }
}

.homepage-carousel .orbit-bullets button.is-active {
  background: #e8e8e8;
}

@media print, screen and (width >= 40em) {
  .main-container {
    min-height: 20rem;
    padding-bottom: 3rem;
  }
}

@media screen and (width >= 64em) {
  .main-container {
    min-height: 35rem;
  }
}

.hidden {
  display: none;
}

h1, h2 {
  font-weight: inherit;
}

h1 small, h2 small {
  color: inherit;
}

h1 .inline-label, h2 .inline-label {
  font-weight: inherit;
}

h3, h4, h5, h6 {
  font-family: Raleway, Helvetica, Arial, sans-serif;
  font-weight: 500;
}

h3 small, h4 small, h5 small, h6 small {
  color: inherit;
}

h3 .inline-label, h4 .inline-label, h5 .inline-label, h6 .inline-label {
  font-weight: inherit;
}

th h1, th h2, th h3, th h4, th h5, th h6 {
  text-align: left;
  margin-bottom: 0;
}

h1.tight, h2.tight, h3.tight, h4.tight, h5.tight, h6.tight, ul.tight, ol.tight, dl.tight, p.tight {
  margin-bottom: 0;
}

ul.no-bullet, ol.no-bullet {
  list-style-type: none;
}

li {
  margin-bottom: 1rem;
}

li.no-bullet {
  list-style-type: none;
}

.tight li, .menu li, .accordion li {
  margin-bottom: 0;
}

ol.tournament-important-dates li {
  padding-left: .5rem;
}

ol.tournament-important-dates li label {
  display: inline-block;
}

ol.tournament-important-dates li.past {
  list-style-type: "✓";
}

ol.tournament-important-dates li.past label {
  text-decoration: line-through;
}

ol.tournament-important-dates li.today {
  list-style-type: "!!";
}

ol.tournament-important-dates li.today label {
  color: #f74545;
}

ol.tournament-important-dates li.soon {
  list-style-type: "▶";
}

ol.tournament-important-dates li.soon label {
  color: #b1590c;
}

ol.tournament-important-dates li.future {
  list-style-type: "▸";
}

@media screen and (width <= 39.9375em) {
  p.img {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.no-header-margin {
  margin-top: -30px;
}

footer {
  color: #fff;
  background-color: #0b1459;
  font-size: 16px;
  font-weight: 700;
}

footer a, footer a:hover {
  color: #fff;
}

footer .copyright {
  color: #cfcfcf;
  padding-top: 60px;
  padding-bottom: 1rem;
  font-weight: 400;
  display: block;
}

footer .social {
  text-align: right;
  background-color: #333;
  font-weight: 500;
}

footer .social a {
  margin: 7px 0 7px 10px;
  display: inline-block;
}

footer .social a img {
  width: 36px;
  height: 36px;
}

footer .menu {
  margin-top: 20px;
  margin-left: -10px;
}

.announcement-bar {
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  background-color: #870707;
  border: .5rem solid #0b1459;
  padding: 5px;
  font-family: Raleway, Helvetica, Arial, sans-serif;
  font-size: 24px;
  font-weight: 600;
}

.announcement-bar a {
  color: #fff;
  text-decoration: underline;
}

.news {
  background-color: #e8e8e8;
  padding: 1rem;
}

.news .module {
  padding: 1rem;
}

.news .module .date {
  font-style: italic;
}

.news .hidden {
  display: none;
}

.news .read-more-link {
  text-align: center;
  text-transform: uppercase;
  color: #333;
}

.news .read-more-link .button {
  margin: 0;
}

.news.expanded .hidden {
  display: inherit;
}

.news-standalone-date {
  margin-bottom: .5rem;
  font-style: italic;
  font-weight: 600;
}

.eligibility-image-container, .echelon-image-container {
  min-width: 20px;
  padding: 0;
}

.table-of-contents {
  background-color: #e8e8e8;
  margin-bottom: 1rem;
  padding: 1rem;
}

.table-of-contents.full-size {
  clear: both;
}

@media screen and (width >= 40em) {
  .table-of-contents:not(.full-size) {
    float: right;
    border-left: 2px solid #cfcfcf;
    margin-bottom: 2rem;
    margin-left: 2rem;
  }
}

.table-of-contents h3 {
  margin-bottom: .5rem;
}

.table-of-contents ol {
  margin-bottom: 0;
}

.table-of-contents ol li {
  margin-bottom: .5rem;
}

.table-of-contents ol li:last-child {
  margin-bottom: 0;
}

.table-of-contents.full-size:not(.custom-columns) ol {
  list-style-position: inside;
}

@media print, screen and (width >= 40em) {
  .table-of-contents.full-size:not(.custom-columns) ol {
    column-count: 3;
    -webkit-column-count: 3;
    -moz-column-count: 3;
  }
}

@media print, screen and (width >= 64em) {
  .table-of-contents.full-size:not(.custom-columns) ol {
    column-count: 4;
    -webkit-column-count: 4;
    -moz-column-count: 4;
  }
}

.section-jump-container h1, .section-jump-container h2, .section-jump-container h3, .section-jump-container h4, .section-jump-container h5, .section-jump-container h6 {
  padding-right: 4rem;
  display: inline;
}

.section-jump-container .section-jump-buttons {
  float: right;
}

.section-jump-container + div {
  clear: both;
}

.map-key {
  background-color: #e8e8e8;
  padding: 1rem 1rem 0;
}

@media screen and (width >= 40em) {
  .figure-float-left {
    float: left;
    margin: 0 2rem 2rem 0;
  }

  .figure-float-right {
    float: right;
    margin: 0 0 2rem 2rem;
  }
}

.accordion-item {
  border: 1px solid #cfcfcf;
  margin-top: -1px;
}

.accordion-item:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.accordion-item:last-child {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.accordion-title {
  color: #000;
  background-image: url("expand.5cb5adc2.png");
  background-position: 20px 15px;
  background-repeat: no-repeat;
  background-size: 50px 50px;
  padding: 30px 1em 30px 80px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
}

@media print, screen and (width >= 40em) {
  .accordion-title {
    font-size: 1rem;
    line-height: 1.5;
  }
}

@media print, screen and (width >= 64em) {
  .accordion-title {
    font-size: 1.28571rem;
    line-height: 1.5;
  }
}

.accordion-title:hover, .accordion-title:focus {
  color: inherit;
}

.is-active .accordion-title {
  background-image: url("collapse.1f46b7d6.png");
}

.accordion-content {
  border: none;
  padding-left: 80px;
}

.map {
  height: 160px;
  margin-bottom: 1rem;
}

@media print, screen and (width >= 40em) {
  .map {
    height: 380px;
  }
}

@media print, screen and (width >= 64em) {
  .map {
    height: 600px;
  }
}

.biography {
  margin-bottom: 1rem;
}

.biography h2, .biography h3 {
  margin-bottom: 0;
}

.new {
  color: #b1590c;
  font-weight: 600;
}

h1 .new, h2 .new, h3 .new, h4 .new, h5 .new, h6 .new {
  font-weight: inherit;
}

.new a {
  color: #512906 !important;
  font-weight: inherit !important;
  text-decoration: underline dotted !important;
}

.example {
  font-weight: 600;
}

.example.correct {
  color: #006d1a;
}

.example.incorrect {
  color: #f74545;
}

ol.outline {
  list-style: upper-alpha;
}

ol.outline .section-header {
  font-weight: bold;
}

ol.outline li ol {
  list-style: decimal;
}

ol.outline li ol li ol {
  list-style: lower-alpha;
}

ol.outline li ol li ol li ol {
  list-style: lower-roman;
}

section + section {
  margin-top: 2rem;
}

.survey-question-note {
  font-size: 85%;
  font-style: italic;
}

.survey-choice .survey-answer {
  font-weight: bold;
}

.survey-answer.numeric {
  text-align: right;
}

.rules-term, .ygk-term, .inline-label, .hashtag, .rules-term a, .ygk-term a, .inline-label a, .hashtag a {
  font-weight: 600;
}

.hashtag:before {
  content: "#";
}

abbr[title] {
  border-bottom: unset;
}

[title]:not([title=""]):not(.stealth-tooltip):not(.select2-selection__rendered) {
  cursor: help;
  text-decoration: underline dotted;
}

dl dt:not(.inline-label) {
  font-family: Fjalla One, sans-serif;
  font-weight: 500;
}

dl dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

.winner-background {
  background-color: #07ff42 !important;
}

.qualification-background {
  background-color: #a0ffb7 !important;
}

.alias-background, .elimination-background {
  background-color: #f8dded !important;
}

.game.tiebreaker {
  background-color: #f3d05e !important;
}

.game.playoff {
  background-color: #f4a662 !important;
}

.game.tiebreaker.playoff {
  background: repeating-linear-gradient(315deg, #f3d05e, #f3d05e 14px, #f4a662 14px 28px);
}

code {
  background-color: inherit;
  border: none;
  padding: 0;
}

th.rotate-outer-container, td.rotate-outer-container {
  vertical-align: bottom;
}

.rotate-container {
  justify-content: center;
  align-items: flex-end;
  display: flex;
}

.rotate-content {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}

th.rotated, td.rotated {
  vertical-align: top;
}

@media screen and (width >= 40em) {
  .medium-two-text-columns {
    columns: 2;
  }

  .medium-two-text-columns p {
    break-inside: avoid;
  }

  .medium-three-text-columns {
    columns: 3;
  }

  .medium-three-text-columns p {
    break-inside: avoid;
  }

  .medium-four-text-columns {
    columns: 4;
  }

  .medium-four-text-columns p {
    break-inside: avoid;
  }
}

@media screen and (width >= 64em) {
  .large-two-text-columns {
    columns: 2;
  }

  .large-two-text-columns p {
    break-inside: avoid;
  }

  .large-three-text-columns {
    columns: 3;
  }

  .large-three-text-columns p {
    break-inside: avoid;
  }

  .large-four-text-columns {
    columns: 4;
  }

  .large-four-text-columns p {
    break-inside: avoid;
  }
}

#username_search_results, .password-mismatch {
  font-weight: 700;
}

.username-invalid, .password-mismatch {
  color: #f74545;
}

.username-valid {
  color: #006d1a;
}

.staffing-status-NONE, .staffing-status-EARLY_DECLINE, .staffing-status-DECLINED {
  opacity: .4;
}

.staffing-status-CONFIRMED {
  color: #006d1a;
}

.staffing-status-WITHDRAWN, .staffing-status-INTEREST, .staffing-status-CANCELED, .staffing-status-HELD, .staffing-status-NO_SHOW, .staffing-status-EARLY_INTEREST {
  color: #b1590c;
}

.staffing-status-ACCEPTED, .staffing-status-INVITED, .staffing-status-UNSPECIFIED {
  color: #dbab10;
}

.invitation-status-ACCEPTED {
  color: #006d1a;
}

.invitation-status-DECLINED, .invitation-status-WITHDRAWN, .invitation-status-EXPIRED {
  color: #b1590c;
}

.map {
  padding: 0;
}

.info-window h2 {
  font-size: 1.5rem;
}

.info-window p {
  margin-bottom: .3rem;
}

.info-window .button {
  padding: 9px;
  font-size: 1rem;
}

.field-size-history-growth {
  color: #006d1a;
}

.field-size-history-shrinkage {
  color: #f74545;
}

.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

summary {
  cursor: pointer;
  display: block;
}

summary:not(.custom-show-hide-icon):before {
  content: ">";
  padding-right: .5rem;
}

summary::-webkit-details-marker {
  display: none;
}

details[open] > summary:not(.custom-show-hide-icon):before {
  content: "v";
}

.collapsed-by-default {
  display: none;
}

p > a, li > a, dd > a, td > a, blockquote > a {
  font-weight: 500;
  text-decoration: underline dotted;
}

p > a:hover, li > a:hover, dd > a:hover, td > a:hover, blockquote > a:hover {
  text-decoration: underline;
}

p > em, li > em, dd > em, td > em, blockquote > em {
  font-weight: 600;
}

.schedule.tournament-date, .schedule .tournament {
  margin-bottom: 2rem;
}

table.advent-calendar td {
  padding: .5rem;
}

table.advent-calendar td img {
  width: 83px;
  height: 107px;
}

figcaption {
  text-align: center;
  width: 0;
  min-width: 100%;
}

figcaption:not(.orbit-caption) {
  font-style: italic;
}

figcaption:first-child {
  margin-bottom: 1rem;
}

figcaption:last-child {
  margin-top: 1rem;
}

figcaption i, figcaption em {
  font-style: normal;
}

figure.sponsor:not(:first-child), figure.hsnct20for20:not(:first-child) {
  margin-top: 1rem;
}

@media screen and (width >= 40em) {
  figure.sponsor {
    max-width: 400px;
  }
}

@media screen and (width <= 39.9375em) {
  .tournament-logo-no-specific-year, .details-logo-sponsor-block, figure.sponsor.floated, figure.hsnct20for20 {
    width: 100%;
  }
}

@media screen and (width >= 40em) {
  .tournament-logo-no-specific-year, .details-logo-sponsor-block, figure.sponsor.floated, figure.hsnct20for20, figure.news-image {
    float: right;
    max-width: 320px;
    margin: 0 0 2rem 2rem;
  }
}

.hidden-off-screen {
  visibility: hidden;
  position: absolute;
  left: -9999px;
  overflow: hidden;
}

.clear-before {
  clear: both;
  margin-top: 1rem;
}

.tournament-details-schedule {
  width: 100%;
  display: table !important;
}

.tournament-details-schedule .time {
  width: 15%;
}

.tournament-details-schedule .event {
  width: 40%;
}

.tournament-details-schedule .location:not([colspan]) {
  width: 30%;
}

.tournament-details-schedule .area {
  width: 15%;
}

.standalone-homepage-image {
  margin-bottom: 1rem;
}

.normal-weight {
  font-weight: normal;
}

.schedule-current {
  background-color: #a0ffb7 !important;
}

table.scoresheet {
  border: 2px solid #777;
}

table.scoresheet th, table.scoresheet td {
  border: 1px solid #cfcfcf;
}

table.scoresheet thead, table.scoresheet thead tr.team-names {
  border-bottom: 2px solid #777;
}

table.scoresheet thead tr.team-names .team-name-container {
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  display: flex;
}

table.scoresheet tbody {
  border-bottom: 2px solid #777;
}

table.scoresheet tbody tr.scoresheet-cycle {
  background-color: inherit;
}

table.scoresheet tbody tr.scoresheet-cycle.scoresheet-cycle-alt-shading {
  background-color: #e8e8e8;
}

table.scoresheet tfoot tr {
  background-color: #fff;
}

table.scoresheet tfoot tr.header-recap {
  border-bottom: 2px solid #777;
}

table.scoresheet tfoot tr:last-child {
  background-color: #e8e8e8;
  border-top: 2px solid #777;
}

table.scoresheet col.cumulative-score {
  border-right: 2px solid #777;
}

table.scoresheet col.bonus-parts {
  border-left: 2px solid #777;
}

table.scoresheet col.cycle-counter {
  border-left: 2px solid #777;
  border-right: 2px solid #777;
}

table.scoresheet th.scoresheet-cell, table.scoresheet td.scoresheet-cell {
  text-align: center;
}

table.scoresheet th.scoresheet-cell.absent, table.scoresheet td.scoresheet-cell.absent {
  background-color: #cfcfcf;
}

table.scoresheet .faded-zero {
  color: #777;
}

.preserve-whitespace {
  white-space: pre-wrap;
}
/*# sourceMappingURL=style.8eaa26c1.css.map */
