@import 'mixins';

.menu-overlay {
	display: none;
}

@include breakpoint(medium down) {
	.breadcrumbs {
		display: none;
	}

	.menu-toggle-buttons img,
	img.brand {
		height: 45px;
	}

	.menu-toggle-buttons {
		text-align: right;
	}

	.menu-container {
		position: fixed;
		right: 0px;
		top: 0px;
		height: 100%;
		background-color: $white;
		width: $mobile-menu-width;
		z-index: 100;
		visibility: hidden;
		overflow: scroll;

		li {
			display: block;
		}
	}

	.secondary-menu {
		display: none;
	}

	body {
		transition: margin 0.25s;
	}

	body.mobile-nav-open {
		margin-left: -$mobile-menu-width;
		margin-right: $mobile-menu-width;

		.menu-container {
			visibility: visible;
		}

		.menu-overlay {
			display: block;
			position: fixed;
			top: 0px;
			left: -$mobile-menu-width;
			width: 100%;
			height: 150%; // account for disappearing browser UI on scroll
			background-color: $overlay-color;
			z-index: 100;
		}
	}

	li.is-drilldown-submenu-parent a:after {
		content: '>';
		float: right;
		padding-right: 0.5rem;
		color: $dark-gray;
	}

	li.js-drilldown-back {
		& a:after {
			content: '';
		}

		& a:before {
			content: '< ';
			font-weight: 300;
		}
	}

	header.top-bar.header {
		ul.main-menu {
			width: $mobile-menu-width;

			.submenu.is-active {
				min-height: 100%;
			}

			li + li {
				border-top: 1px solid $light-gray;
			}

			li.secondary-menu-item {
				border-top: none;
			}

			li.js-drilldown-back {
				font-weight: 300;
				border-bottom: 2px solid map-get($foundation-palette, primary);
				min-height: 69px;
				padding-top: 15px;
			}
		}
	}
}

@include breakpoint(large) {
	.menu-toggle-buttons {
		display: none;
	}

	img.brand {
		height: 82px;
	}
}

.no-js {
	@include breakpoint(small only) {
		.top-bar {
			display: none;
		}
	}

	@include breakpoint(medium) {
		.title-bar {
			display: none;
		}
	}
}
