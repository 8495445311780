@media print {
	@page {
		margin: 0.25in 0.5in 0.5in 0.5in;
	}

	header {
		&.top-bar.header {
			border-top: 0 !important;
		}

		.menu-toggle-buttons,
		.menu-container {
			display: none;
		}
	}

	img.brand {
		height: 1in;
	}

	.button-menu {
		display: none;
	}

	.module:not(.printing) {
		display: none;
	}

	// Some browsers put the href attribute of a link after the link text, when printing. We don't want that.
	a[href]:after {
		content: none !important;
	}

	blockquote {
		border: none;
	}

	footer {
		.social,
		.links {
			display: none;
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		break-after: avoid;
		page-break-after: avoid;

		+ p,
		+ table {
			break-before: avoid;
			page-break-before: avoid;
		}
	}

	.break-before {
		break-before: page;
		page-break-before: always;
	}

	.avoid-break-inside,
	avoid-break-inside * {
		break-inside: avoid;
		page-break-inside: avoid;
		-webkit-column-break-inside: avoid;
	}

	.non-printing {
		display: none;
	}
}
