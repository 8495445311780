@import 'mixins';

header.top-bar.header {
	// NB: this border styling is duplicated in the maintenance site, so if you change it here, you should change it there, too: 
	// https://github.com/naqtqb/misc/blob/main/naqt-maintenance/src/pages/index.astro#L21
	border-top: 7px solid map-get($foundation-palette, secondary);
	border-bottom: 1px solid $light-gray;

	margin-bottom: $page-header-margin-bottom;

	box-shadow: $standard-box-shadow;

	font-family: $header-font-family;

	a {
		color: map-get($foundation-palette, menu);
	}

	@include breakpoint(large) {
		ul.main-menu,
		ul.secondary-menu {
			/* Normally this would be just .top-bar-right, but using that class exposes a bug in
			 * Foundation's Dropdown Menu, so we add these three styles manually.
			 */
			clear: right;
			float: right;
			width: auto;

			.unloaded {
				display: none;
			}
		}

		ul.is-dropdown-submenu {
			min-width: 250px;
			font-family: $body-font-family;
			font-size: 16px;
		}

		ul.first-sub {
			ul {
				border: 1px solid $light-gray;
			}
		}

		ul.main-menu > li.is-active {
			/* & > a {
				border-bottom: 1px solid map-get($foundation-palette, primary);
			} */

			li.is-dropdown-submenu-parent:after {
				content: '>';
				position: absolute;
				top: 0;
				right: 0;
				padding-right: 0.5rem;
				color: $dark-gray;
			}

			li.is-active {
				background-color: $light-gray;
			}
		}

		li {
			display: inline-block;

			&.secondary-menu-item {
				display: none;
			}

			&.is-submenu-item {
				&:hover {
					background-color: $light-gray;
				}

				a {
					width: 100%; // to increase the click target area
				}
			}

			a {
				display: inline-block;
				padding: 4px 2px 0px 2px;
				margin-left: 5px;

				&.search {
					background-image: url(../images/searchicon.png);
					padding-left: 23px;
					background-size: auto 24px;
					background-position: -3px -1px;
					background-repeat: no-repeat;
					height: 28px;
				}
			}
		}
	}
}

#menuBackground {
	position: absolute;
	width: 100%;
	top: 130px;
	background-color: white;
	box-shadow: $standard-box-shadow;
}

.secondary-menu,
.secondary-menu-item {
	font-family: $body-font-family;
	font-weight: 300;

	a {
		font-weight: 300;
	}

	@include breakpoint(large) {
		li + li {
			&:before {
				content: '|';
			}
		}

		li a {
			margin-right: 5px;
		}
	}
}

.main-menu {
	padding-top: 36px;
	font-size: 16px;

	@include breakpoint(large) {
		z-index: 20;
		font-size: 18px;
		& > li + li {
			&:before {
				content: '|';
			}
		}

		& > li.secondary-menu-item + li {
			&:before {
				content: none;
			}
		}

		ul {
			li {
				font-weight: 500;
			}
		}
	}
}

.breadcrumbs {
	font-weight: 700;

	a {
		font-weight: normal;
		text-decoration: none;
	}

	li {
		&.ancestor {
			font-weight: normal;
		}

		&.current {
			font-weight: 600;
		}
	}

	li:not(:last-child):after {
		color: $black;
		font-weight: normal;
	}
}
