.question {
	font-family: 'Merriweather', $body-font-family;

	.question-body {
		.power {
			font-weight: 700;

			&::after {
				content: ' (\2605)';
			}
		}

		margin-bottom: 0.5rem;
	}

	.question-intro {
		margin-bottom: 0.5rem;
	}

	.question-parts {
		list-style-type: upper-alpha;
	}

	.question-answer {
		&:not(.suppress-answer-marker) {
			&::before {
				content: 'answer: ';
			}
		}

		.required {
			text-decoration: underline;
			text-underline-position: under;
		}
	}

	.pg {
		font-size: 95%;
	}

	.host-note {
		font-style: italic;

		&::before {
			content: '[';
		}

		&::after {
			content: ']';
		}
	}
}

.pg {
	&::before {
		content: ' [';
	}

	&::after {
		content: ']';
	}

	font-family: $header-font-family;
	font-weight: 400;
	font-size: 85%;
	color: $dark-gray;
}

.priority-protest {
	background-color: #ff7f50;
}
