.homepage-carousel {
	margin-bottom: 15px;

	.orbit-slide {
		@include breakpoint(small down) {
			height: 160px;
		}

		@include breakpoint(medium) {
			height: 337px;
		}

		@include breakpoint(large) {
			height: 441px;
		}

		figure {
			margin: 0px;
		}

		.tournament-promo-logo-container {
			height: 100%;
			padding-top: 1rem;
			text-align: center;
			@include breakpoint(small down) {
				display: flex;
				align-items: center;
				.left-half {
					flex: auto;
					white-space: nowrap;
					overflow: hidden;
				}
				.right-half {
					flex: 0 1 auto;
					a.button {
						@media screen and (max-width: 325px) {
							max-width: 8rem;
						}
						display: block;
						margin-bottom: 0.5rem;
						text-overflow: ellipsis;
						white-space: nowrap;
						overflow: hidden;
					}
				}
			}
			.tournament-promo-logo {
				@include breakpoint(small down) {
					height: 125px;
				}

				@include breakpoint(medium) {
					height: 210px;
				}

				@include breakpoint(large) {
					height: 310px;
				}
			}
		}

		.orbit-caption {
			@include breakpoint(small down) {
				font-size: 20px;
			}

			@include breakpoint(medium) {
				font-size: 28px;
			}

			@include breakpoint(large) {
				font-size: 36px;
			}

			& a {
				color: inherit;
			}

			& h3 {
				font-size: 75%;
				margin-bottom: 0px;
			}

			& p {
				@include breakpoint(small down) {
					display: none;
				}
				font-size: 40%;
				margin-bottom: 0px;
				font-family: $body-font-family;
				& a:not(.button) {
					text-decoration: underline;
				}
			}

			font-family: $header-font-family;
			backdrop-filter: blur(10px);

			bottom: 45px;
			@include breakpoint(small down) {
				bottom: 0px;
			}
		}
	}

	.orbit-previous,
	.orbit-next {
		img {
			width: 36px;
		}

		@include breakpoint(small down) {
			position: static; /* override from Orbit's built-in styling on small screens */
			transform: none;
			border-radius: 9999px;
			padding: 0.5rem 1rem;

			img {
				width: 15px;
			}
		}
	}

	@include breakpoint(small down) {
		.orbit-navigation {
			margin-top: 5px;
			position: relative;
			background-color: rgba($black, 0.4);
			border-radius: 9999px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	.orbit-bullets {
		@include breakpoint(small down) {
			display: inline-block;
			margin: 0px 0px 20px;
			height: 10px;
		}

		@include breakpoint(medium) {
			position: absolute;
			bottom: 15px;
			width: 100%;
			margin: 0px;
			height: 15px;
		}

		button {
			width: 15px;
			height: 15px;
			@include breakpoint(small down) {
				width: 10px;
				height: 10px;
			}

			background-color: inherit;

			border: 1px solid $light-gray;

			&.is-active {
				background: $light-gray;
			}
		}
	}
}
