@import 'mixins';

table {
	thead {
		border-bottom: 1px solid $light-gray;

		th {
			white-space: nowrap;
		}
	}

	tbody {
		th,
		td {
			vertical-align: baseline;
		}
	}

	tfoot {
		td {
			font-weight: $global-weight-normal;
		}
	}

	caption {
		font-weight: $global-weight-normal;
		font-style: italic;
	}

	th {
		text-align: left;

		.secondary,
		h1,
		h2 {
			font-weight: normal;
		}
	}

	th.center,
	td.center {
		text-align: center;
	}

	th,
	td {
		label {
			font: inherit;
			background: inherit;
			color: inherit;
		}

		&.numeric {
			text-align: right;
			font-variant-numeric: lining-nums tabular-nums;
			-webkit-font-feature-settings: 'lnum';
			-moz-font-feature-settings: 'lnum';
			font-feature-settings: 'lnum';
		}
	}

	&.no-left-padding {
		th:first-child,
		td:first-child {
			padding-left: 0;
		}
	}

	&.no-datatables {
		display: block;
		overflow-x: auto;
	}

	&.unstriped tr.striped {
		background-color: $table-striped-background;
	}
}

.dataTables_wrapper {
	margin-bottom: 1rem;
}
.dt-floating-wrapper {
	position: fixed;
	overflow: hidden;

	table.dataTable {
		margin: 0 !important;
		position: relative;
	}
}

.dataTables_empty {
	display: none;
}

$table-sort-indicator-size: 25px;
.dataTable {
	.sorting {
		background-image: url(../images/table-sort-unsorted.png) !important;
		background-size: $table-sort-indicator-size;
	}

	.sorting_asc {
		background-image: url(../images/table-sort-asc.png) !important;
		background-size: $table-sort-indicator-size;
	}

	.sorting_desc {
		background-image: url(../images/table-sort-desc.png) !important;
		background-size: $table-sort-indicator-size;
	}
}

section#player-info + section#tournament-info {
	margin-top: 0;
}
