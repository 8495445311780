@import 'mixins';

$checkbox-size: 50px;
$checkbox-image-padding: 9px;
$checkbox-target-size: $checkbox-size - 2 * $checkbox-image-padding;

// $text-like-input-fields: "input[type='text'], input[type='text'], input[type='password'], input[type='date'], input[type='datetime'], input[type='datetime-local'], input[type='month'], input[type='week'], input[type='email'], input[type='number'], input[type='search'], input[type='tel'], input[type='time'], input[type='url']";

$text-like-input-fields: (
	"input[type='text']",
	"input[type='text']",
	"input[type='password']",
	"input[type='date']",
	"input[type='datetime']",
	"input[type='datetime-local']",
	"input[type='month']",
	"input[type='week']",
	"input[type='email']",
	"input[type='number']",
	"input[type='search']",
	"input[type='tel']",
	"input[type='time']",
	"input[type='url']",
	'.sq-input'
);
$text-based-form-fields: ($text-like-input-fields, 'select', 'textarea');

form.inline {
	display: inline;
}

select {
	&:not(.select2) {
		@include background-triangle($select-triangle-color);
		background-origin: content-box;
		background-position: $global-right (-$form-spacing) center;
		background-repeat: no-repeat;
		background-size: 9px 6px;

		padding-#{$global-right}: ($form-spacing * 1.5);
	}

	&:focus {
		border: $input-border-focus;
		background-color: $input-background-focus;
		outline: none;
		box-shadow: $input-shadow-focus;

		@if has-value($input-transition) {
			transition: $input-transition;
		}
	}
}

input.has-been-focused:invalid,
input.has-been-focused:focus:invalid,
select.has-been-focused:invalid,
select.has-been-focused:focus:invalid,
input.incorrect,
select.incorrect {
	border: $input-border-invalid;
	box-shadow: $input-shadow-invalid;
}

select.inline {
	width: inherit;
}

.help-text {
	em {
		// It's already italic, so we have to do something else for emphasis.
		font-weight: 600;
	}
}

.help-text:first-child {
	margin-top: inherit;
}

.help-text.middle {
	@include form-label-middle;
}

.row .stack-below-checkbox,
.indented-by-checkbox-width {
	padding-left: $checkbox-size - 4px;
}

.custom-checkbox,
.custom-radio {
	display: inline-block;

	& .custom-indicator {
		width: $checkbox-target-size;
		height: $checkbox-target-size;
		display: inline-block;
		margin-left: -($checkbox-size - $checkbox-image-padding);
		position: relative;
		top: 11px;
		background-size: $checkbox-size $checkbox-size;
		background-position-x: -$checkbox-image-padding;
		background-position-y: -$checkbox-image-padding;
		cursor: pointer;
	}

	& input {
		opacity: 0;
		width: $checkbox-size;
	}

	& label {
		display: inline-block;
		position: relative;
		left: 6px;
	}
}

.custom-checkbox {
	input:enabled ~ .custom-indicator {
		background-image: url(../images/checkbox-enabled-unchecked.png);
	}

	input:enabled:focus ~ .custom-indicator {
		background-image: url(../images/checkbox-enabled-unchecked-focused.png);
	}

	input:enabled:checked ~ .custom-indicator {
		background-image: url(../images/checkbox-enabled-checked.png);
	}

	input:enabled:checked:focus ~ .custom-indicator {
		background-image: url(../images/checkbox-enabled-checked-focused.png);
	}

	input:disabled ~ .custom-indicator {
		background-image: url(../images/checkbox-disabled-unchecked.png);
		cursor: not-allowed;
	}

	input:disabled:checked ~ .custom-indicator {
		background-image: url(../images/checkbox-disabled-checked.png);
		cursor: not-allowed;
	}
}

.custom-radio {
	input:enabled ~ .custom-indicator {
		background-image: url(../images/radio-enabled-unchecked.png);
	}

	input:enabled:focus ~ .custom-indicator {
		background-image: url(../images/radio-enabled-unchecked-focused.png);
	}

	input:enabled:checked ~ .custom-indicator {
		background-image: url(../images/radio-enabled-checked.png);
	}

	input:enabled:checked:focus ~ .custom-indicator {
		background-image: url(../images/radio-enabled-checked-focused.png);
	}

	input:disabled ~ .custom-indicator {
		background-image: url(../images/radio-disabled-unchecked.png);
		cursor: not-allowed;
	}

	input:disabled:checked ~ .custom-indicator {
		background-image: url(../images/radio-disabled-checked.png);
		cursor: not-allowed;
	}
}

.label {
	@include label;
}

label,
.label {
	// @include label;

	&.middle {
		@include breakpoint(small down) {
			padding-bottom: 0;
			margin-bottom: 0;
		}
	}

	&.required::after {
		font-weight: 300;
		color: $dark-gray;
		content: ' (required)';
	}

	&.optional::after {
		font-weight: 300;
		color: $dark-gray;
		content: ' (optional)';
	}
}

th,
td {
	@each $field in $text-based-form-fields {
		> #{$field} {
			display: inline-block !important; // Without this, Firefox renders such cells with incorrect vertical alignment.	Chrome is fine either way.	As Firefox progresses we should periodically re-check whether this is necessary.
			width: inherit;
			height: inherit;

			&:only-child {
				margin-bottom: 0;
			}

			&.full-width {
				width: 100%;
			}
		}
	}
}

.select2 {
	@include form-select;
	background-image: none; // The form-select mixin will use background-image to add a triangle, but select2 will add its own.
}

.select2-results li,
.select2-search input {
	margin-bottom: inherit;
}

.select2-container--default .select2-selection--single {
	border: none;
}

.select2-container--default {
	margin-bottom: 0px;

	.select2-selection--single {
		.select2-selection__rendered {
			line-height: normal;
		}
	}
}

.xdsoft_datetimepicker {
	font-family: $body-font-family;

	.xdsoft_label {
		& > .xdsoft_select > div > .xdsoft_option:hover {
			background-color: map-get($foundation-palette, 'secondary');
		}
	}

	.xdsoft_calendar td:hover,
	.xdsoft_timepicker .xdsoft_time_box > div > div:hover {
		background-color: map-get($foundation-palette, 'secondary') !important;
	}

	.xdsoft_calendar td.xdsoft_today {
		color: map-get($foundation-palette, 'primary');
	}

	.xdsoft_label > .xdsoft_select > div > .xdsoft_option.xdsoft_current {
		background-color: $dark-gray;
	}

	.xdsoft_calendar td.xdsoft_default,
	.xdsoft_calendar td.xdsoft_current,
	.xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current {
		background: $dark-gray;
		box-shadow: $black 0 1px 3px 0 inset;
	}
}

select[multiple] {
	padding: 0px;
}

.inline-fields {
	@each $field in $text-like-input-fields {
		#{$field} {
			display: inline-block;
			width: inherit;
		}
	}
	select {
		display: inline-block;
		width: inherit;
	}

	.inline {
		width: unset;

		&.date-pair {
			width: 47%;
		}

		/* &.state {
			width: 38%;
		}
		
		&.postal-code {
			width: 60%;
		} */
	}

	p {
		white-space: nowrap;
		label {
			display: inline;
		}
	}

	label.inline {
		display: inline-block;
		margin: 1rem;

		&:first-child {
			margin-left: 0;
		}
	}
}

.field-group {
	background-color: $medium-gray;
	&.transparent {
		background-color: inherit;
	}

	margin-bottom: 1rem;
	&.tight {
		margin-bottom: 0;
	}

	fieldset {
		&:not(.unpadded) {
			padding: 1rem;
		}

		&.only-vertical-padding {
			padding: 1rem 0;
		}

		&.initial-checkbox {
			padding-top: 0px;
		}

		&.tight {
			padding: 0;
		}

		&.right-padding {
			padding-right: 1rem;
		}
	}
}

.filter-box-button {
	display: none;
	@include breakpoint(small down) {
		display: block;
		background-color: $light-gray;
		font-weight: 700;
		color: $black;
		text-transform: uppercase;
		padding: 1rem;
		margin-bottom: 1rem;

		&:after {
			content: '>';
			float: right;
		}

		&:hover {
			color: inherit;
		}

		&.is-active {
			color: inherit;
			margin-bottom: 0;
		}

		&.is-active:after {
			content: 'V';
		}

		& + .filter-box {
			display: none;
		}

		&.is-active + .filter-box {
			display: block;
		}
	}
}

.filter-box {
	background-color: $light-gray;
	padding: 1rem;
	margin-bottom: 1rem;

	li {
		padding-right: 1rem;
		vertical-align: top;

		> label,
		legend {
			font-weight: 600;
			input,
			select,
			button,
			.select2-selection {
				font-family: $body-font-family;
				font-weight: normal;
				font-size: 16px;
			}
		}

		> .button {
			margin-top: 1.5rem;
			margin-bottom: 0.5rem;

			&.no-top-margin {
				@include breakpoint(medium up) {
					margin-top: 0;
				}
			}
		}
	}
}

/* The following is to reset styles on the custom search element */
.gsc-control-cse {
	* {
		-moz-box-sizing: content-box !important;
		-webkit-box-sizing: content-box !important;
		box-sizing: content-box !important;
	}

	input {
		box-shadow: none !important;

		:focus {
			outline: none;
		}
	}

	table {
		border: none;
		background: transparent;
		td.gsib_b {
			padding: 0px;
		}
	}
}

table.gsc-completion-container tbody tr {
	background: transparent;
}

.gsc-input-box,
.gsc-search-box .gsc-input > input,
.gsc-input-box-hover {
	-moz-box-shadow: none !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}

.error,
.errors {
	color: map-get($foundation-palette, alert);
	font-weight: bold;

	&.label,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	h1 &,
	h2 &,
	h3 &,
	h4 &,
	h5 &,
	h6 & {
		font-weight: normal;
	}

	em {
		font-weight: inherit;
	}

	a {
		color: darken(map-get($foundation-palette, alert), 20%) !important;
		font-weight: inherit !important;
		text-decoration: underline dotted !important;
	}
}

.error-background {
	color: map-get($foundation-palette, alert);
}

.important-instruction,
.important-instructions {
	&::before {
		content: '→';
		font-weight: bold;
	}
}

.warning,
.warnings,
.important-instruction,
.important-instructions {
	color: $orange;
	font-weight: bold;

	&.label,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	h1 &,
	h2 &,
	h3 &,
	h4 &,
	h5 &,
	h6 & {
		font-weight: normal;
	}

	em {
		font-weight: inherit;
	}

	a {
		color: darken($orange, 20%) !important;
		font-weight: inherit !important;
		text-decoration: underline dotted !important;
	}
}

.warning-background {
	background-color: $orange;
}

.caution {
	color: $yellow;
	font-weight: bold;

	&.label,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	h1 &,
	h2 &,
	h3 &,
	h4 &,
	h5 &,
	h6 & {
		font-weight: normal;
	}

	em {
		font-weight: inherit;
	}

	a {
		color: darken($yellow, 20%) !important;
		font-weight: inherit !important;
		text-decoration: underline dotted !important;
	}
}

.error-background {
	background-color: $yellow;
}

.success {
	color: map-get($foundation-palette, success);
	font-weight: bold;

	&.label,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	h1 &,
	h2 &,
	h3 &,
	h4 &,
	h5 &,
	h6 & {
		font-weight: normal;
	}

	em {
		font-weight: inherit;
	}

	a {
		color: lighten(map-get($foundation-palette, success), 10%) !important;
		font-weight: inherit !important;
		text-decoration: underline dotted !important;
	}
}

.success-background {
	background-color: map-get($foundation-palette, success);
}

p.help-text {
	margin-bottom: 0;

	&.before-field {
		margin-top: 1rem;
	}
}

.of-subform.deleting {
	background-color: #fff;
	opacity: 0.3;
}

.moderator-evaluation {
	.slider-container {
		margin-bottom: 2rem;

		input[type='range'][data-interacted='false'] {
			// we don't want there to be a pre-populated value, but truly achieving that is apparently impossible with <input type="range">. so we hide the thumb that indicates the value.
			&::-webkit-slider-thumb {
				border-color: transparent;
				background-color: transparent;
				box-shadow: none;
			}
			&::-moz-range-thumb {
				border-color: transparent;
				background-color: transparent;
				box-shadow: none;
			}
			&::-ms-thumb {
				border-color: transparent;
				background-color: transparent;
				box-shadow: none;
			}
		}

		.slider-value {
			text-align: right;

			@include breakpoint(medium up) {
				font-size: 0.85rem; // so that the longer explanations don't run to a second line
			}
		}

		.slider-header-labels {
			display: flex;
			flex-direction: row;

			div {
				flex: 1;
				text-align: center;

				&:first-child {
					text-align: left;
				}

				&:last-child {
					text-align: right;
				}
			}
		}
	}

	.speed-explanation {
		padding-left: 4rem;
	}
}
