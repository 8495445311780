.buzzword-wordmark-container {
	.buzzword-underwriter-logo {
		float: right;
	}
}

.buzzword-wordmark {
	width: 95%;
	max-width: 400px;
	margin-bottom: 1rem;

	@media print {
		width: 300px;
	}
}

.buzzword-logo-container {
	float: right;
}

.buzzword-logo {
	@include breakpoint(small down) {
		display: none;
	}

	@include breakpoint(medium up) {
		width: 150px;
		height: 150px;
		margin-bottom: 1rem;
	}

	@media print {
		width: 150px;
	}
}

.buzzword-underwriter-logo {
	width: 150px;
	margin-bottom: 1rem;
}

.buzzword-special-event-logo {
	@include breakpoint(small down) {
		display: none;
	}

	@include breakpoint(medium up) {
		width: 250px;
		height: 250px;
		float: right;
		margin-bottom: 1rem;
	}
}

.buzzword-special-event-quote {
	background-color: $light-gray;
	padding: 1rem;

	@include breakpoint(medium up) {
		clear: both;
		float: right;
		width: 50%;
	}

	figure {
		max-width: 150px;
		float: right;
	}

	:last-child {
		margin-bottom: 0;
	}
}

.buzzword-previous-question-result {
	@include breakpoint(small) {
		margin-bottom: 1rem;
	}

	@include breakpoint(medium up) {
		&:not(.standalone) {
			margin-left: 2rem;
			border-left: 2px solid $medium-gray;
		}

		margin-bottom: 2rem;
	}

	padding: 1rem;

	background-color: $light-gray;

	h3 {
		margin-bottom: 0.5rem;
	}
}

.buzzword-hint {
	font-size: 150%;
}

.buzzword-question-container {
	margin-right: 2rem;
}

.buzzword-big-button {
	display: block;
	height: 100%;
	width: 100%; // which on medium up is only half the width, because of the two-column setup
	margin-top: 0.5rem;
	margin-bottom: 0;
}

.buzzword-toggle-sound-effects {
	@include breakpoint(medium up) {
		width: 50%;
	}
}

.buzzword-timer {
	font-family: $header-font-family;
	font-size: 300%;
	text-align: center;
	margin-top: 0.5rem;

	// When answering, and only when on larger screens, we add some top margin so that the timer lines up with the answer-entry field.
	.buzzword-answering & {
		@include breakpoint(medium up) {
			margin-top: 2rem;
		}
	}
}

.buzzword-hidden {
	display: none;
}

.buzzword-review-question-table {
	th:first-child {
		@include breakpoint(medium up) {
			width: 20%;
		}
	}
}

.buzzword-stats-is-self {
	background-color: #a4adf2 !important;
}

.buzzword-stats-counted-performance {
	background-color: #a4adf2 !important;
}

.buzzword-menus,
.buzzword-stats-menus {
	clear: both;

	nav {
		margin-top: 0.5rem;
	}
}

.buzzword-awards-container {
	// This reflects BuzzwordUtils.AWARDS_TO_DISPLAY (it's that times the width in .buzzword-awards-inline plus some spacing).
	min-width: 74px;

	@include breakpoint(medium up) {
		min-width: 98px;
	}
}

.buzzword-awards-inline {
	display: inline-block;
	white-space: nowrap;
}

.buzzword-award-inline {
	width: 1.5rem;

	a & {
		cursor: pointer;
	}

	@include breakpoint(medium up) {
		width: 2.5rem;
	}
}

.buzzword-award-display-container {
	width: 6rem;

	+ td {
		vertical-align: middle;
	}
}

.buzzword-award-display {
	width: 5rem;
}

.buzzword-award-explanation-container {
	figure {
		width: 10rem;
		margin-left: auto;
		margin-right: auto;

		.buzzword-award-display {
			width: 100%;
		}
	}

	@include breakpoint(small) {
		margin-bottom: 2rem;
	}
}

.buzzword-results-filter {
	@include breakpoint(medium up) {
		li {
			&:not(:last-child) {
				margin-right: 4rem;
			}

			input[type='submit'] {
				margin-top: 0;
			}
		}
	}
}

.buzzword-coach-registration-total-display-outer-container {
	position: fixed;
	bottom: 0;
	right: 20%;
	max-width: 400px;
	background-color: white;
	z-index: 1000;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	padding: 2px 2px 0 2px;
}

.buzzword-coach-registration-total-display-inner-container {
	border-top: 2px solid map-get($foundation-palette, secondary);
	border-left: 2px solid map-get($foundation-palette, secondary);
	border-right: 2px solid map-get($foundation-palette, secondary);
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	margin: 2px 2px 0 2px;
	padding: 1rem;

	@include breakpoint(medium up) {
		padding-bottom: 4rem;
	}
}

.coach-registration-price:not(:first-child) {
	margin-left: 0.3rem;
}

.overridden-price {
	font-weight: normal;
	text-decoration: line-through;
	text-decoration-thickness: 2px;
}

.coach-registration-price-unit {
	font-weight: normal;
}
