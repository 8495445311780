@import 'mixins';

.button {
	padding: 12px 12px;
	font-family: $header-font-family;
	font-size: 18px;
	text-decoration: none;

	&.disabled,
	&[disabled] {
		opacity: inherit;
		background-color: $dark-gray;

		&.busy {
			cursor: wait;
		}

		&:hover {
			background-color: $dark-gray;
		}
	}

	&.small-full-width {
		line-height: 24px; // Not logically part of this, but I think it applies in the same situations

		@include breakpoint(small down) {
			width: 100%;
		}

		@include breakpoint(medium) {
			width: 50%;
		}

		@include breakpoint(large up) {
			width: 25%;
		}
	}
}

.windows {
	.button {
		padding: 14px 12px 10px;
	}
}

.button-group {
	@include breakpoint(small down) {
		display: none;
	}

	&.expanded {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		flex-wrap: wrap;

		.button {
			flex: 1 1 content;

			&.label {
				flex: 0 0 10%;
			}
		}
	}

	.button {
		background-color: $medium-gray;
		color: map-get($foundation-palette, primary);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: $global-font-size;

		&.active {
			background-color: $white;
			border: 1px solid $medium-gray;
			color: #000;
		}

		&.disabled {
			background-color: $light-gray;
			color: $dark-gray;
		}

		&.label {
			display: inline-block;
			background-color: $white;
			color: $black;
			cursor: unset;
		}
	}
}
