$page-header-margin-bottom: 30px;
$mobile-menu-width: 275px;
$overlay-color: rgba(51, 51, 51, 0.5);

@import 'fonts';
@import 'mixins';
@import 'mobile-nav';
@import 'desktop-nav';
@import 'modules';
@import 'buttons';
@import 'tables';
@import 'forms';
@import 'print';
@import 'social';
@import 'buzzword';
@import 'questions';
@import 'carousel';

@include breakpoint(medium) {
	.main-container {
		min-height: 20rem;
		padding-bottom: 3rem;
	}
}

@include breakpoint(large up) {
	.main-container {
		min-height: 35rem;
	}
}

.hidden {
	display: none;
}

h1,
h2 {
	font-weight: inherit;

	small {
		color: inherit;
	}

	.inline-label {
		font-weight: inherit;
	}
}

h3,
h4,
h5,
h6 {
	font-weight: 500;
	font-family: $body-font-family;

	small {
		color: inherit;
	}

	.inline-label {
		font-weight: inherit;
	}
}

th h1,
th h2,
th h3,
th h4,
th h5,
th h6 {
	margin-bottom: 0;
	text-align: left;
}

h1.tight,
h2.tight,
h3.tight,
h4.tight,
h5.tight,
h6.tight,
ul.tight,
ol.tight,
dl.tight,
p.tight {
	margin-bottom: 0;
}

ul.no-bullet,
ol.no-bullet {
	list-style-type: none;
}

li {
	margin-bottom: 1rem;

	&.no-bullet {
		list-style-type: none;
	}

	.tight & {
		margin-bottom: 0;
	}
}

.menu li,
.accordion li {
	margin-bottom: 0;
}

ol.tournament-important-dates {
	li {
		padding-left: 0.5rem;

		label {
			display: inline-block;
		}

		&.past {
			list-style-type: '\2713'; // check mark

			label {
				text-decoration: line-through;
			}
		}

		&.today {
			list-style-type: '!!';

			label {
				color: map-get($foundation-palette, alert);
			}
		}

		&.soon {
			list-style-type: '\25B6'; // large right-pointing triangle

			label {
				color: $orange;
			}
		}

		&.future {
			list-style-type: '\25B8'; // small right-pointing triangle
		}
	}
}

@include breakpoint(small down) {
	$small-grid-column-gutter: 20px;

	p.img {
		margin-left: -$small-grid-column-gutter;
		margin-right: -$small-grid-column-gutter;
	}
}

.no-header-margin {
	margin-top: -$page-header-margin-bottom;
}

footer {
	background-color: map-get($foundation-palette, secondary);
	color: $white;
	font-size: 16px;
	font-weight: 700;

	a {
		color: $white;
		&:hover {
			color: $white;
		}
	}

	.copyright {
		display: block;
		padding-top: 60px;
		padding-bottom: 1rem;
		color: $medium-gray;
		font-weight: 400;
	}

	.social {
		font-weight: 500;
		background-color: $black;
		text-align: right;

		a {
			display: inline-block;
			margin: 7px 0px 7px 10px;
			img {
				height: 36px;
				width: 36px;
			}
		}
	}

	.menu {
		margin-top: 20px;
		margin-left: -$menu-item-padding;
	}
}

.announcement-bar {
	text-align: center;
	font-family: $body-font-family;
	font-weight: 600;
	background-color: map-get($foundation-palette, primary);
	color: $white;
	text-transform: uppercase;
	font-size: 24px;
	padding: 5px;
	border: 0.5rem solid map-get($foundation-palette, secondary);

	a {
		color: $white;
		text-decoration: underline;
	}
}

.news {
	padding: 1rem;
	background-color: $light-gray;

	.module {
		padding: 1rem;

		.date {
			font-style: italic;
		}
	}

	.hidden {
		display: none;
	}

	.read-more-link {
		text-align: center;
		text-transform: uppercase;
		color: $black;

		.button {
			margin: 0;
		}
	}

	&.expanded .hidden {
		display: inherit;
	}
}

.news-standalone-date {
	font-style: italic;
	font-weight: 600;
	margin-bottom: 0.5rem;
}

.eligibility-image-container,
.echelon-image-container {
	min-width: 20px;
	padding: 0;
}

.table-of-contents {
	&.full-size {
		clear: both;
	}

	&:not(.full-size) {
		@include breakpoint(medium up) {
			margin-left: 2rem;
			margin-bottom: 2rem;
			border-left: 2px solid $medium-gray;
			float: right;
		}
	}

	@include breakpoint(small) {
		margin-bottom: 1rem;
	}

	padding: 1rem;

	background-color: $light-gray;

	h3 {
		margin-bottom: 0.5rem;
	}

	ol {
		li {
			margin-bottom: 0.5rem;

			&:last-child {
				margin-bottom: 0;
			}
		}

		margin-bottom: 0;
	}

	&.full-size:not(.custom-columns) ol {
		@include breakpoint(medium) {
			// columns: 3;
			column-count: 3;
			-webkit-column-count: 3;
			-moz-column-count: 3;
		}

		@include breakpoint(large) {
			// columns: 4;
			column-count: 4;
			-webkit-column-count: 4;
			-moz-column-count: 4;
		}

		list-style-position: inside;
	}
}

.section-jump-container {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		display: inline;
		padding-right: 4rem;
	}

	.section-jump-buttons {
		float: right;
	}
}

.section-jump-container + div {
	// Without this, DataTables can interfere with the "jump to section" buttons
	clear: both;
}

.map-key {
	padding: 1rem 1rem 0 1rem;
	background-color: $light-gray;
}

@include breakpoint(medium up) {
	.figure-float-left {
		margin: 0 2rem 2rem 0;
		float: left;
	}

	.figure-float-right {
		margin: 0 0 2rem 2rem;
		float: right;
	}
}

.accordion-item {
	border: 1px solid $medium-gray;
	margin-top: -1px;

	&:first-child {
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}

	&:last-child {
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
	}
}

.accordion-title {
	padding: 30px 1em 30px 80px;
	color: #000;
	font-weight: 700;
	background-image: url(../images/expand.png);
	background-repeat: no-repeat;
	background-size: 50px 50px;
	background-position: 20px 15px;

	@each $size, $headers in $header-styles {
		@include breakpoint($size) {
			font-size: rem-calc(map-get(map-get($headers, 'h3'), 'font-size'));
			line-height: 1.5;
		}
	}

	&:hover,
	&:focus {
		color: inherit;
	}
}

.is-active .accordion-title {
	background-image: url(../images/collapse.png);
}

.accordion-content {
	padding-left: 80px;
	border: none;
}

.map {
	@include breakpoint(small) {
		height: 160px;
	}

	@include breakpoint(medium) {
		height: 380px;
	}

	@include breakpoint(large) {
		height: 600px;
	}

	margin-bottom: 1rem;
}

.biography {
	margin-bottom: 1rem;

	h2,
	h3 {
		margin-bottom: 0;
	}
}

.new {
	font-weight: 600;
	color: $orange;
	h1 &,
	h2 &,
	h3 &,
	h4 &,
	h5 &,
	h6 & {
		font-weight: inherit;
	}

	a {
		color: darken($orange, 20%) !important;
		font-weight: inherit !important;
		text-decoration: underline dotted !important;
	}
}

.example {
	font-weight: 600;

	&.correct {
		color: map-get($foundation-palette, success);
	}

	&.incorrect {
		color: map-get($foundation-palette, alert);
	}
}

ol.outline {
	list-style: upper-alpha;

	.section-header {
		font-weight: bold;
	}

	li {
		ol {
			list-style: decimal;

			li {
				ol {
					list-style: lower-alpha;

					li {
						ol {
							list-style: lower-roman;
						}
					}
				}
			}
		}
	}
}

section + section {
	margin-top: 2rem;
}

.survey-question-note {
	font-size: 85%;
	font-style: italic;
}

.survey-choice .survey-answer {
	font-weight: bold;
}

.survey-answer.numeric {
	text-align: right;
}

.rules-term,
.ygk-term,
.inline-label,
.hashtag {
	font-weight: 600;

	a {
		font-weight: 600;
	}
}

.hashtag {
	&::before {
		content: '#';
	}
}

abbr[title] {
	border-bottom: unset;
}

[title]:not([title='']):not(.stealth-tooltip):not(.select2-selection__rendered) {
	cursor: help;
	text-decoration: underline;
	text-decoration-style: dotted;
}

dl {
	dt {
		&:not(.inline-label) {
			@include label;
		}
	}

	dd {
		margin-left: 1rem;
		margin-bottom: 1rem;
	}
}

.winner-background {
	background-color: $winner-color !important;
}

.qualification-background {
	background-color: $qualification-color !important;
}

.alias-background {
	background-color: $alias-color !important;
}

.elimination-background {
	background-color: $alias-color !important;
}

.game {
	&.tiebreaker {
		background-color: $tiebreaker-color !important;
	}

	&.playoff {
		background-color: $playoff-color !important;
	}

	&.tiebreaker.playoff {
		background: repeating-linear-gradient(
			315deg,
			$tiebreaker-color,
			$tiebreaker-color 14px,
			$playoff-color 14px,
			$playoff-color 28px
		);
	}
}

code {
	// Get rid of some ugly styles provided by Foundation
	background-color: inherit;
	border: none;
	padding: 0;
}

th.rotate-outer-container,
td.rotate-outer-container {
	vertical-align: bottom;
}

.rotate-container {
	display: flex;
	align-items: flex-end;
	justify-content: center;
}

.rotate-content {
	writing-mode: vertical-rl;
	transform: rotate(180deg);
}

th.rotated,
td.rotated {
	vertical-align: top; // gets converted to bottom via transform on .rotated
}

@include breakpoint(medium up) {
	.medium-two-text-columns {
		columns: 2;

		p {
			break-inside: avoid;
		}
	}

	.medium-three-text-columns {
		columns: 3;

		p {
			break-inside: avoid;
		}
	}

	.medium-four-text-columns {
		columns: 4;

		p {
			break-inside: avoid;
		}
	}
}

@include breakpoint(large up) {
	.large-two-text-columns {
		columns: 2;

		p {
			break-inside: avoid;
		}
	}

	.large-three-text-columns {
		columns: 3;

		p {
			break-inside: avoid;
		}
	}

	.large-four-text-columns {
		columns: 4;

		p {
			break-inside: avoid;
		}
	}
}

#username_search_results,
.password-mismatch {
	font-weight: 700;
}

.username-invalid,
.password-mismatch {
	color: map-get($foundation-palette, alert);
}

.username-valid {
	color: map-get($foundation-palette, success);
}

.staffing-status-NONE,
.staffing-status-EARLY_DECLINE,
.staffing-status-DECLINED {
	opacity: 0.4;
}

.staffing-status-CONFIRMED {
	color: map-get($foundation-palette, success);
}

.staffing-status-WITHDRAWN,
.staffing-status-INTEREST,
.staffing-status-CANCELED,
.staffing-status-HELD,
.staffing-status-NO_SHOW,
.staffing-status-EARLY_INTEREST {
	color: $orange;
}

.staffing-status-ACCEPTED,
.staffing-status-INVITED,
.staffing-status-UNSPECIFIED {
	color: $yellow;
}

.invitation-status-ACCEPTED {
	color: map-get($foundation_palette, success);
}

.invitation-status-DECLINED,
.invitation-status-WITHDRAWN,
.invitation-status-EXPIRED {
	color: $orange;
}

.map {
	padding: 0;
}

.info-window {
	h2 {
		font-size: 1.5rem;
	}

	p {
		margin-bottom: 0.3rem;
	}

	.button {
		padding: 9px;
		font-size: 1rem;
	}
}

.field-size-history-growth {
	color: map-get($foundation-palette, success);
}

.field-size-history-shrinkage {
	color: map-get($foundation-palette, alert);
}

.unselectable {
	-moz-user-select: -moz-none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

summary {
	display: block;
	cursor: pointer;

	&:not(.custom-show-hide-icon)::before {
		content: '>';
		padding-right: 0.5rem;
	}

	&::-webkit-details-marker {
		display: none;
	}
}

details[open] > summary:not(.custom-show-hide-icon)::before {
	content: 'v';
}

.collapsed-by-default {
	display: none;
}

p,
li,
dd,
td,
blockquote {
	> a { // Direct descendent only because otherwise it stops <strong> from working
		font-weight: 500;
		text-decoration: dotted underline;
		&:hover {
			text-decoration: underline;
		}
	}

	> em {
		font-weight: 600;
	}
}

.schedule {
	&.tournament-date {
		margin-bottom: 2rem;
	}

	.tournament {
		margin-bottom: 2rem;
	}
}

table.advent-calendar {
	td {
		padding: 0.5rem;

		img {
			width: 83px;
			height: 107px;
		}
	}
}

figcaption {
	min-width: 100%;
	width: 0;
	text-align: center;

	&:not(.orbit-caption) {
		font-style: italic;
	}

	&:first-child {
		margin-bottom: 1rem;
	}

	&:last-child {
		margin-top: 1rem;
	}

	i,
	em {
		font-style: normal;
	}
}

figure.sponsor,
figure.hsnct20for20 {
	&:not(:first-child) {
		margin-top: 1rem;
	}
}

@include breakpoint(medium up) {
	figure.sponsor {
		max-width: 400px;
	}
}

@include breakpoint(small only) {
	.tournament-logo-no-specific-year,
	.details-logo-sponsor-block,
	figure.sponsor.floated,
	figure.hsnct20for20 {
		width: 100%;
	}
}

@include breakpoint(medium up) {
	.tournament-logo-no-specific-year,
	.details-logo-sponsor-block,
	figure.sponsor.floated,
	figure.hsnct20for20,
	figure.news-image {
		max-width: 320px;
		float: right;
		margin: 0rem 0rem 2rem 2rem;
	}
}

.hidden-off-screen {
	position: absolute;
	left: -9999px;
	overflow: hidden;
	visibility: hidden;
}

.clear-before {
	margin-top: 1rem;
	clear: both;
}

.tournament-details-schedule {
	display: table !important;
	width: 100%;

	.time {
		width: 15%;
	}

	.event {
		width: 40%;
	}

	.location:not([colspan]) {
		width: 30%;
	}

	.area {
		width: 15%;
	}
}

.standalone-homepage-image {
	margin-bottom: 1rem;
}

.normal-weight {
	font-weight: normal;
}

.schedule-current {
	background-color: $schedule-current-color !important;
}

table.scoresheet {
	border: 2px solid $dark-gray;

	th,
	td {
		border: 1px solid $medium-gray;
	}

	thead {
		border-bottom: 2px solid $dark-gray;

		tr.team-names {
			border-bottom: 2px solid $dark-gray;

			.team-name-container {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: baseline;
			}
		}
	}

	tbody {
		border-bottom: 2px solid $dark-gray;

		tr.scoresheet-cycle {
			background-color: inherit;

			&.scoresheet-cycle-alt-shading {
				background-color: $light-gray;
			}
		}
	}

	tfoot {
		tr {
			background-color: $white;

			&.header-recap {
				border-bottom: 2px solid $dark-gray;
			}

			&:last-child {
				border-top: 2px solid $dark-gray;
				background-color: $light-gray;
			}
		}
	}

	col.cumulative-score {
		border-right: 2px solid $dark-gray;
	}

	col.bonus-parts {
		border-left: 2px solid $dark-gray;
	}

	col.cycle-counter {
		border-left: 2px solid $dark-gray;
		border-right: 2px solid $dark-gray;
	}

	th.scoresheet-cell,
	td.scoresheet-cell {
		text-align: center;

		&.absent {
			background-color: $medium-gray;
		}
	}

	.faded-zero {
		color: $dark-gray;
	}
}

.preserve-whitespace {
	white-space: pre-wrap;
}
