@import 'mixins';

$module-padding: 20px;
$module-padding-top: 43px;

.module {
	position: relative;
	box-shadow: $standard-box-shadow;
	padding: $module-padding-top $module-padding;
	margin-bottom: 20px;
	background-color: $white;
	border-radius: 5px;
	border: 1px solid $light-gray;
	overflow: hidden;

	&.payment-instrument {
		padding: 1.5rem;

		.card-icon {
			width: 4rem;
			float: right;
		}

		.button {
			margin-bottom: 0;
		}
	}

	a.entirety {
		color: $white;
		font-weight: bold;
		text-decoration: underline;

		&::after {
			content: ' >>';
		}
	}

	&[style*='background-image'] {
		background-size: cover;
		background-position: center;
		color: $white;
		padding: 0px;

		h1,
		h2,
		h3 {
			color: $white;

			a {
				color: $white;
			}
		}

		p a {
			color: inherit;
			font-weight: bold;

			&.button {
				font-weight: 400;
			}
		}

		.overlay {
			background-color: $overlay-color;
			padding: $module-padding-top $module-padding;
			height: 100%;
		}
	}

	.button-pinned {
		height: 43px;
		.button {
			position: absolute;
			bottom: $module-padding-top;
			margin: 0px;
		}
	}
}
